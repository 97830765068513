import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { scroller } from 'react-scroll';
import ModalSf from '../ModalSf/ModalSf';
import './Header.css';
import { Context } from '../../globalState/context/context';

const Header = () => {
  const context = useContext(Context);
  let navigate = useNavigate();
  const [openAuthModal, setOpenAuthModal] = useState(false);

  // перемещение к якорям
  const handleScroll = (target) => {
    navigate('/');

    setTimeout(() => {
      scroller.scrollTo(target, {
        duration: 500,
        delay: 0,
        offset: -120,
        smooth: 'easeInOutQuart'
      });
    }, 200);
  };

  // открывает закрывает  мобильное меню
  const toggleMobileMenuHandler = () => {
    context.dispatch({ type: 'MOBILE_MENU', data: !context.state.stateMobileMenu });
  }

  return (
    <div className="header">
      <div className="header__top_part">
        <a
          href="https://site.deltaleasing.ru"
          className="header__wrap_logo"
          target="_blank"
          rel="noreferrer"
        >
          <img src={require('../../assets/logo.svg').default} alt="Логотип" />
        </a>

        <div className="header__wrap_sig_in_and_mob_menu">
          <a
            href="tel:8 800 200-01-80"
            className="header__wrap_phone"
          >
            8 800 200-01-80
          </a>

          <a
            href="https://online.deltaleasing.ru/login"
            target="_blank"
            className="header__wrap_sig_in"
            rel="noreferrer"
          >
            <span className="header__word_sig_in">Онлайн-офис</span>
            <img className="header__img_sig_in" src={require('../../assets/sig-in.svg').default} alt="Войти" />
          </a>

          <div
            className="header__wrap_mob_menu"
            onClick={toggleMobileMenuHandler}
          >
            {context.state.stateMobileMenu && <img src={require('../../assets/mob-menu-close.svg').default} alt="Меню" />}
            {!context.state.stateMobileMenu && <img src={require('../../assets/mob-menu.svg').default} alt="Меню" />}
          </div>
        </div>
      </div>

      <div className="header__bottom_part">
        <div className="header__wrap_nav">
          <div
            className="header__wrap_nav_item"
            onClick={() => handleScroll('unique-solutions')}
          >
            Лизинговые решения
          </div>
          <div
            className="header__wrap_nav_item"
            onClick={() => handleScroll('calculator')}
          >
            Калькулятор лизинга
          </div>
          <a
            href="https://site.deltaleasing.ru/news"
            className="header__wrap_nav_item"
            target="_blank"
            rel="noreferrer"
          >
            Новости
          </a>
          <a
            href="https://site.deltaleasing.ru/otzyvy"
            className="header__wrap_nav_item"
            target="_blank"
            rel="noreferrer"
          >
            Бизнес-истории
          </a>
          <a
            href="https://site.deltaleasing.ru/partners"
            className="header__wrap_nav_item"
            target="_blank"
            rel="noreferrer"
          >
            Акции и партнёры
          </a>
          <a
              href="https://site.deltaleasing.ru/contacts"
              className="header__wrap_nav_item"
              target="_blank"
              rel="noreferrer"
          >
            Контакты
          </a>

        </div>
      </div>

      <div
        className="header__send_a_request"
        onClick={() => context.dispatch({ type: 'MODAL', data: { state: true, type: 'online_application' }, })}
      >
        Отправить заявку
      </div>

      <ModalSf
        typeModal="auth"
        open={openAuthModal}
        handleClose={() => setOpenAuthModal(false)}
      />
    </div>
  );
};

export default Header;