/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useContext } from 'react';
import { fetchHelper } from '../../helpers/api-helper';
import Container from '../../components/Container/Container';
import CardConditions from '../../components/CardConditions/CardConditions';
import UniqueSolutionBlock from '../../components/UniqueSolutionBlock/UniqueSolutionBlock';
// import PromotionSlider from '../../components/PromotionSlider/PromotionSlider';
import RegionalNetwork from '../../components/RegionalNetwork/RegionalNetwork';
import Representative from '../../components/Representative/Representative';
import StepsForm from '../../components/StepsForm/StepsForm';
import Layout from '../../layout/Layout';
import { cardConditions } from '../../helpers/common-helper';
import uniqueSolutionImg from '../../assets/unique-solution-img.jpg';
import MainSlider from '../../components/MainSlider/MainSlider';
import { Context } from '../../globalState/context/context';
import './Main.css';

function Main() {
  const context = useContext(Context);

  // слайды для главного слайдера
  const arrMainSlider = [
    {
      arrImg: [
        {img: require('../../assets/main_slide1-2.jpg').default},
        {img: require('../../assets/main_slide1-1.jpg').default},
        {img: require('../../assets/main_slide1.jpg').default},
        {img: require('../../assets/main_slide1-big.jpg').default},
      ],
      title: 'Мы знаем как',
      description: [
        { text: 'С 1999 года на рынке лизинговых услуг' },
        { text: '24 офиса компании от Калининграда до&nbsp;Сахалина' },
        { text: 'Эксперт в индустриальном сегменте' },
        { text: '40 000 единиц работающей техники' },
      ],
      onClick: () => context.dispatch({ type: 'MODAL', data: { state: true, type: 'online_application' }}),
      titleBtn: 'Отправить заявку',
      descriptionBtn: 'Персональный менеджер в вашем регионе поможет оформить лизинг быстрее',
      typeBtn: 'first',
    },
    {
      arrImg: [
        {img: require('../../assets/main_slide2-2.jpg').default},
        {img: require('../../assets/main_slide2-1.jpg').default},
        {img: require('../../assets/main_slide2.jpg').default},
        {img: require('../../assets/main_slide2-big.jpg').default},
      ],
      title: 'Видим будущее в&nbsp;Вашем бизнесе',
      description: [
        { text: 'Аванс от 0% на транспорт и спецтехнику' },
        { text: 'Индивидуальный график' },
        { text: 'Без ограничений по сумме сделки' },
        { text: 'Работаем с удаленными регионами' },
      ],
      href: 'https://site.deltaleasing.ru/start-at-zero',
      titleBtn: 'Подробнее',
      typeBtn: 'third',
    },
    {
      arrImg: [
        {img: require('../../assets/main_slide3-2.jpg').default},
        {img: require('../../assets/main_slide3-1.jpg').default},
        {img: require('../../assets/main_slide3.jpg').default},
        {img: require('../../assets/main_slide3-big.jpg').default},
      ],
      title: 'Мы онлайн',
      description: [
        { text: 'Отслеживание платежей' },
        { text: 'Одобрение новых сделок' },
        { text: 'Электронный документооборот' },
        { text: 'Уведомление о страховом случае' },
      ],
      href: 'https://online.deltaleasing.ru/login',
      titleBtn: 'Онлайн-офис',
      typeBtn: 'third',
    },
  ];

  // апи для селектов
  useEffect(() => {
    (async function () {
      try {
        const response = await fetchHelper(`/landing/v1/directories`, {});
        const data = await response.json();

        if (response?.status === 200) {
          context.dispatch({ type: 'GET_DIRECTORIES', data: data });
        }

        if (response?.status === 400 || response?.status === 404) {
          context.dispatch({ type: 'GET_DIRECTORIES_ERROR', data: data });
        }

        if (response?.status === 422) {
          context.dispatch({ type: 'GET_DIRECTORIES_ERROR', data: {message: data} });
        }
        
        
      } catch (err) {
        console.log('ошибка в GET запросе');
        context.dispatch({ type: 'GET_DIRECTORIES_ERROR', data: { message: 'К сожалению, произошла ошибка, пожалуйста, повторите попытку позже.' } });
      }
    })()
  }, []);

  // апи для блока акций
  // useEffect(() => {
  //   (async function () {
  //     try {
  //       const response = await fetchHelper(`/landing/v1/promotions?show_on_landing=1`, {});
  //       const data = await response.json();
  //       dispatch({ type: 'GET_PROMOTIONS', data });
  //     } catch (err) {
  //       console.log('ошибка в GET запросе promotions');
  //       dispatch({ type: 'GET_PROMOTIONS_ERROR', data: err });
  //     }
  //   })()
  // }, [dispatch]);

  return (
    <div className="app">
      <Layout>
        <Container
          background='linear-gradient(90deg, #009999 0%, #38BABA 0.01%, #009999 25.28%, #225C9C 100%)'
          className='app__wrap_slider'
          classNameInner='app__wrap_inner_slider'
        >
          <MainSlider photos={arrMainSlider}/>
        </Container>
      
        <Container background='#EBF0F5'>
          <div className="app__wrap_static_container_for_form">
            <div className="app__wrap_form">
              <StepsForm />
            </div>
          </div>
          <div className="app__wrap_static_container_for_all_card" id="unique-solutions">
            <div className="app__wrap_all_card">
              {cardConditions.map((item, index) => 
                <div key={index}>
                  <CardConditions data={item} />
                </div>
              )}
            </div>
          </div>
        </Container>

        <Container background={`url(${uniqueSolutionImg})`}>
          <UniqueSolutionBlock />
        </Container>
        
        {/* {state?.responsePromotions?.length > 0
          && (
            <Container background='#00646E'>
              <div className="app__wrap_production_slider">
                <PromotionSlider photos={state?.responsePromotions} />
              </div>
            </Container>
          )} */}

        <Container background='#fff'>
          <RegionalNetwork />
        </Container>
        
        {context.state?.stateDirectories?.offices?.length > 0
          && (
            <Container background='#fff'>
              <Representative offices={context.state?.stateDirectories?.offices} />
            </Container>
          )}
      </Layout>
    </div>
  );
}

export default Main;
