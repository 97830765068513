import React, { useContext } from 'react';
import './RegionalNetwork.css';
import ButtonSf from '../UI/ButtonSf/ButtonSf';
import { Context } from '../../globalState/context/context';

const RegionalNetwork = () => {
  const context = useContext(Context);

  return (
    <div className="regional_network" id="target-contacts">
      <div className="regional_network__wrap_title_and_description">
        {/* <h2 className="regional_network__title">
          Региональная сеть DeltaЛизинг включает 24 представительства
        </h2> */}
        <h2
          className="regional_network__title"
          dangerouslySetInnerHTML={{ __html: 'Региональная сеть DeltaЛизинг включает <br class="br-regional_network-768"/> 24 представительства' }}
        />

        <div className="regional_network__description">
          Мы эффективно решаем задачи бизнеса более чем в 500 городах России — наши специалисты 
          разработают финансовое решение индивидуально под ваш проект
        </div>
      </div>
      
      <div className="regional_network__wrap_phone_and_btn">
        <div className="regional_network__wrap_phone_and_description">
          <a href="tel: 8 800 200-01-80" className="regional_network__wrap_phone">
            <h2 className="regional_network__phone">
              8 800 200-01-80
            </h2>
          </a>

          <div className="regional_network__phone_description">
            звонок бесплатный по России
          </div>
        </div>

        <div className="regional_network__wrap_btn">
          <ButtonSf
            typeBtn='first'
            onClick={() => context.dispatch({ type: 'MODAL', data: { state: true, type: 'online_application' }, })}
          >
            отправить заявку
          </ButtonSf>
        </div>
      </div>
    </div>
  );
};

export default RegionalNetwork;