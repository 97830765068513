
// для карточек УСЛОВИЯ
export const cardConditions = [
  {
    img: require('../assets/condition-1.jpg').default,
    title: 'Грузовой транспорт',
    typeDescription: 'list',
    description: [
      { title: 'Аванс', description: 'от 0%'},
    ],
    href: 'https://site.deltaleasing.ru/busines/trucks',
  },
  {
    img: require('../assets/condition-2.jpg').default,
    title: 'Оборудование',
    typeDescription: 'list',
    description: [
      { title: 'Аванс', description: 'от 5%'},
    ],
    href: 'https://site.deltaleasing.ru/busines/equipment',
  },
  {
    img: require('../assets/condition-3.jpg').default,
    title: 'Спецтехника',
    typeDescription: 'list',
    description: [
      { title: 'Аванс', description: 'от 0%'},
    ],
    href: 'https://site.deltaleasing.ru/busines/machinery',
  },
  {
    img: require('../assets/condition-4.jpg').default,
    title: 'Автомобили',
    typeDescription: 'list',
    description: [
      { title: 'Аванс', description: 'от 0%'},
    ],
    href: 'https://site.deltaleasing.ru/busines/auto',
  },
];

// для преимуществ
export const arrAdvantage = [
  { title: 'Экономия на налогах' },
  { title: 'Ускоренная амортизация' },
  { title: 'Гибкий график платежей' },
];



// для гугл карты
// export const address = '630132, Новосибирск, ул. Красноярская, 35, офис 501';

// проверяет, пустой объект или нет
export const isEmptyObj = (obj) => Object.keys(obj).length === 0;

// обрезает текст по количеству символов и ставит троеточие в конце
export function changeText(selector, maxlength) {
  for (let el of document.querySelectorAll(selector)) {
    let txt = el.textContent;
    if (txt.length > maxlength)
      el.textContent = txt.slice(0, maxlength - 3) + '...';
  }
}

// придаём секундам формат часы:минуты:секунды
export const convertSecondsToMinutes = (value, needHours = false, letterStyle = false) => {
  const timestamp = value;

  const hours = Math.floor(timestamp / 60 / 60);
  const minutes = Math.floor(timestamp / 60) - (hours * 60);
  const seconds = timestamp % 60;

  const formattedHours = hours.toString().length === 1 ? `0${hours}` : hours;
  const formattedMinutes = minutes.toString().length === 1 ? `0${minutes}` : minutes;
  const formattedSeconds = seconds.toString().length === 1 ? `0${seconds}` : seconds;
  let formatted = null;

  if (needHours) {
    if (!letterStyle) {
      formatted = `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
    } else {
      formatted = `${formattedHours} <span>ч</span> ${formattedMinutes} <span>мин</span> ${formattedSeconds} <span>сек</span>`;
    }
  } else {
    formatted = `${formattedMinutes}:${formattedSeconds}`;
  }
  return formatted;
};

// в зависимости от выбранной валюты будет показываться ее значок
export const badgeValute = (context) => {
  if (context.state.stateStepsForm.selectValute.id === 1) return '₽';
  if (context.state.stateStepsForm.selectValute.id === 2) return '$';
  if (context.state.stateStepsForm.selectValute.id === 3) return '€';
  return '';
};

// в зависимости от выбранной валюты будет показываться соответствующий текст
export const textTooltipValute = (id) => {
  if (id === 1) return 'Российский рубль';
  if (id === 2) return 'Доллар США';
  if (id === 3) return 'Евро';
  return '';
};

// в зависимости от показываемой валюты будет показываться соответствующая иконка
export const iconValute = (name) => {
  if (name === 'RUR') return '₽';
  if (name === 'USD') return '$';
  if (name === 'EUR') return '€';
  return '';
};



