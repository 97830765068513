import React from 'react';
import './TextAreaSf.css';

const TextAreaSf = ({
  validation = {},
  className = '',
  error = '',
}) => {
  return (
    <textarea
      {...validation}
      className={`text_area_sf ${className} ${error ? 'error' : ''}`}
    />
  );
};

export default TextAreaSf;