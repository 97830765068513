import React, { useState } from 'react';
import './ContentModalsPersonalOffer.css';
import ContainerFormElement from '../../../UI/ContainerFormElement/ContainerFormElement';
import InputSf from '../../../UI/InputSf/InputSf';
import ButtonSf from '../../../UI/ButtonSf/ButtonSf';
import TextAreaSf from '../../../UI/TextAreaSf/TextAreaSf';
import CustomInputMask from '../../../UI/CustomInputMask/CustomInputMask';
import { useForm } from "react-hook-form";

const ContentModalsPersonalOffer = () => {

  const defaultValues = {
    inputHowCallYou: '',
    inputINN: '',
    inputPhone: undefined,
    textareaWishes: '',
  };

  const {
    control,
    setValue,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ defaultValues, mode: "onBlur" });

  const [data, setData] = useState(null); // данные со всех полей

  // отправка формы
  const submitForm = (data) => {
    setData(data);
    alert(JSON.stringify(data));
  }

  console.log('data', data);

  return (
    <div className="content_modals_personal_offer">
      <h3 className="content_modals_personal_offer__title">
        Получить персональное предложение
      </h3>

      <form onSubmit={handleSubmit(submitForm)} className="form">
        <ContainerFormElement textLabel="Как к вам обращаться?">
          <InputSf
            placeholder=""
            error={errors.inputHowCallYou}
            validation={{
              ...register("inputHowCallYou", {
                required: "Введите свое имя",
                minLength: {
                  value: 2,
                  message: "Минимум 2 символа"
                }
              })
            }}
          />
          {errors.inputHowCallYou && <p className="steps_form__line__text_error">{errors.inputHowCallYou.message}</p>}
        </ContainerFormElement>

        <div className="content_modals_personal_offer__wrap_double_field">
          <ContainerFormElement textLabel="ИНН">
            <InputSf
              placeholder=""
              error={errors.inputINN}
              maxlength={12}
              onlyNum
              setValue={setValue}
              validation={{
                ...register("inputINN", {
                  required: "Это поле необходимо заполнить",
                })
              }}
            />
            {errors.inputINN && <p className="steps_form__line__text_error">{errors.inputINN.message}</p>}
          </ContainerFormElement>

          <ContainerFormElement textLabel="Контактный телефон">
            <CustomInputMask
              control={control}
              error={errors.inputPhone}
              validation={{
                ...register("inputPhone", {
                  required: "Ведите номер телефона",
                  validate: {
                    inputTelRequired: (v) =>
                      v && v.indexOf("_") === -1 ? undefined : "Введите корректный номер телефона",
                  }
                })
              }}
            />
            {errors.inputPhone && <p className="steps_form__line__text_error">{errors.inputPhone.message}</p>}
          </ContainerFormElement>
        </div>

        <ContainerFormElement textLabel="Ваши пожелания">
          <TextAreaSf
            placeholder=""
            validation={{
              ...register("textareaWishes")
            }}
          />
        </ContainerFormElement>

        <div className="content_modals_personal_offer__text">
          Мы рассмотри ваше пожелание и поможем подобрать для вас наилучший вариант
        </div>

        <div className="content_modals_personal_offer__wrap_btn">
          <ButtonSf
            typeBtn='second'
            type="submit"
          >
            Запросить предложение
          </ButtonSf>
        </div>
      </form>
    </div>
  );
};

export default ContentModalsPersonalOffer;