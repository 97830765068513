import React from 'react';
import './Container.css';

const Container = ({
  children,
  background = '',
  className = '',
  classNameInner = '',
}) => {
  return (
    <div className={`container ${className}`} style={{ background: background}}>
      <div className={`container__inner ${classNameInner}`}>
        {children}
      </div>
    </div>
  );
};

export default Container;