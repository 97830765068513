/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Helmet } from "react-helmet";
import InsightgroupContainer from '../../containers/InsightgroupContainer/InsightgroupContainer';
import Layout from '../../layout/Layout';
import './Insightgroup.css';

function Insightgroup() {

  return (
    <div className="">
      <Helmet>
        <title>Собственник ДельтаЛизинг - Инвестиционная группа «Инсайт»</title>
        <meta name="description" content="Nested component" />
      </Helmet>

      <Layout>
        <InsightgroupContainer />
      </Layout>
    </div>
  );
}

export default Insightgroup;
