import React from 'react';
import LinkSf from '../UI/LinkSf/LinkSf';
import './Footer.css';

const Footer = () => {
  return (
    <div className="footer">
      <div className="footer__wrap_parts footer__wrap_parts__links">
        <div className="footer__middle_part">
          <div className="footer__middle_part__row">
            {/* <LinkSf to="/about" classNameCustom="footer__link">
            Юридическая информация
          </LinkSf>
          <LinkSf to="/about" classNameCustom="footer__link">
            О компании
          </LinkSf> */}
            <div className="footer__wrap_link_middle">
              <a
                className="footer__link_middle"
                href="https://site.deltaleasing.ru/corporate"
                target="_blank" rel="noreferrer"
              >Корпоративная информация</a>
            </div>
            <div className="footer__wrap_link_middle">
              <LinkSf to="/insightgroup">
                Инвестгруппа Инсайт
              </LinkSf>
            </div>
            <div className="footer__wrap_link_middle">
              <a
                className="footer__link_middle"
                href="https://site.deltaleasing.ru/programs"
                target="_blank" rel="noreferrer"
              >Спецпрограммы</a>
            </div>
          </div>
          <div className="footer__middle_part__row">
            <div className="footer__wrap_link_middle">
              <a
                className="footer__link_middle"
                href="https://site.deltaleasing.ru/seized"
                target="_blank" rel="noreferrer"
              >Реализация имущества</a>
            </div>
            <div className="footer__wrap_link_middle">
              <a
                className="footer__link_middle"
                href="https://site.deltaleasing.ru/hr"
                target="_blank" rel="noreferrer"
              >Работа у нас</a>
            </div>
          </div>
        </div>
      </div>
      <div className="footer__wrap_parts">
        <div className="footer__left_part">
          <div className="footer__word_c">© {new Date().getFullYear()} DeltaЛизинг</div>
        </div>

        <div className="footer__right_part">
          {/* <a
            href=""
            target="_blank" rel="noreferrer"
            className="footer__link_company"
          >wwww.com</a> */}
          <a
            href="https://buroburo.ru/"
            target="_blank"
            rel="noreferrer"
            className="footer__link_buroburo"
          >
            <img src={require('../../assets/buroburo.svg').default} alt="Сделано в бюробюро" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;