import React, { useState } from 'react';
import './Tooltip.css';

const Tooltip = ({
  children,
  className = '',
  messageTooltip = '',
  rightPosotion = false,
}) => {
  const [show, setShow] = useState(false);

  const handlerShow = () => setShow(true);
  const handlerHide = () => setShow(false);

  return (
    <div
      className={`tool_tip ${className}`}
      onMouseEnter={handlerShow}
      onMouseLeave={handlerHide}
    >
      {children}
      <div className={`tool_tip__message ${show ? 'active' : ''} ${rightPosotion ? 'right' : ''}`}>
        {messageTooltip}
      </div>
    </div>
  );
};

export default Tooltip;