/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext } from 'react';
import Container from '../../components/Container/Container';
import ButtonSf from '../../components/UI/ButtonSf/ButtonSf';
import { Context } from '../../globalState/context/context';
import './InsightgroupContainer.css';

function InsightgroupContainer() {
  const context = useContext(Context);

  return (
    <div className="insightgroup_container">
      <Container
        className='insightgroup_container__wrap'
      >
        <div className='insightgroup_container__wrap_big_text_and_logo'>
          <div className='insightgroup_container__wrap_logo'>
            <img src={require('../../assets/logo-insight.svg').default} alt="Иконка" />
          </div>

          <div className='insightgroup_container__wrap_big_text'>
            <h5 className='insightgroup_container__big_text'>
              <span className='insightgroup_container__inner_text'>
                100% ООО «ДельтаЛизинг» владеет частная инвестиционная компания «Инсайт» (ООО «Инвестиционная группа Инсайт»).
              </span>
              <span className='insightgroup_container__inner_text'>
                Группа была основана бизнесменом Аветом Миракяном, который является основным владельцем и&nbsp;генеральным директором «Инсайт».
              </span>
              <span className='insightgroup_container__inner_text'>
                Основной род деятельности «Инсайт» – привлечение средств для совершения сделок M&A и&nbsp;управление капиталом в&nbsp;России
                и&nbsp;странах СНГ. «Инсайт» основана профессионалами на&nbsp;рынке корпоративных сделок и&nbsp;обладает широким пулом партнеров
                в&nbsp;финансовой сфере. Группа инвестирует в&nbsp;предприятия с&nbsp;большим потенциалом роста, приобретая контролирующие или
                крупные миноритарные доли в&nbsp;их&nbsp;капитале, фокусируясь преимущественно на&nbsp;компаниях лизинговой отрасли. При совершении
                сделок «Инсайт» ориентируется на&nbsp;сохранение операционной и&nbsp;бизнес-модели активов, целостности команды, уникальных продуктов и&nbsp;сервисов.
              </span>
              <span className='insightgroup_container__inner_text'>
                Перейти на сайт инвестиционной группы «Инсайт»
                <a
                  href="https://insightgroup.ru/"
                  className='insightgroup_container__link'
                  target="_blank"
                  rel="noreferrer"
                >
                  www.insightgroup.ru
                </a>
              </span>
            </h5>
          </div>
        </div>

        <div className='insightgroup_container__info_block'>
          <div className='insightgroup_container__block_for_text'>
            <h3 className='insightgroup_container__name_company'>
              ООО «ДельтаЛизинг»
            </h3>

            <div className='insightgroup_container__many_text'>
              <a
                href="tel:8-800-2000-180"
                className='insightgroup_container__phone'
              >
                8-800-2000-180
              </a>

              <a
                href="mailto:info@deltaleasing.ru"
                className='insightgroup_container__mail_link'
              >
                info@deltaleasing.ru
              </a>

              <a
                href="https://deltaleasing.ru/"
                className='insightgroup_container__delta'
              >
                deltaleasing.ru
              </a>
            </div>
          </div>

          <div className='insightgroup_container__info_block_wrap_btn'>
            <ButtonSf
              typeBtn="second"
              onClick={() => context.dispatch({ type: 'MODAL', data: { state: true, type: 'online_application' }, })}
            >
              Отправить заявку на лизинг
            </ButtonSf>
          </div>
        </div>

      </Container>
    </div>
  );
}

export default InsightgroupContainer;
