import React from 'react';
import Select, { components } from 'react-select';
import { Controller } from "react-hook-form";
import './SelectSf.css';


const SelectSf = ({
  placeholder = '',
  className = '',
  classNamePrefix = '',
  options = [],
  control,
  error,
  name,
  isSearchable = false,
  disabled = false,
}) => {

  // кастомная стрелка
  const DropdownIndicator = props => {
    return (
      <components.DropdownIndicator {...props}>
        <img className="select_sf__custom_arrow" src={require('../../../assets/gray-arr-down.svg').default} alt="Arrow" />
      </components.DropdownIndicator>
    );
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <Select
          {...field}
          aria-labelledby="aria-label"
          getOptionValue={option => option.id} // переименовываем название ключа
          getOptionLabel={option => option.name}  // переименовываем название ключа
          options={options}
          placeholder={placeholder}
          className={`${className} ${error ? 'error' : ''}`}
          classNamePrefix={classNamePrefix}
          components={{ DropdownIndicator }}
          isSearchable={isSearchable}
          isDisabled={disabled}
          // menuIsOpen={true}
        />
      )}
    />
  );
}

export default SelectSf;
/* ссылка на документацию https://react-select.com/home */