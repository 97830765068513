/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-useless-escape */
import React, { useState, useEffect, useContext } from 'react';
import './ContentModalsOnlineApplication.css';
import { fetchHelper } from '../../../../helpers/api-helper';
import Cookies from 'js-cookie';
import ContainerFormElement from '../../../UI/ContainerFormElement/ContainerFormElement';
import InputSf from '../../../UI/InputSf/InputSf';
import SelectSf from '../../../UI/SelectSf/SelectSf';
import ButtonSf from '../../../UI/ButtonSf/ButtonSf';
import InputRange from '../../../UI/InputRange/InputRange';
import CheckboxSf from '../../../UI/CheckboxSf/CheckboxSf';
import CustomInputMask from '../../../UI/CustomInputMask/CustomInputMask';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { Context } from '../../../../globalState/context/context';

const ContentModalsOnlineApplication = ({
  onClose = () => {},
}) => {
  const context = useContext(Context);
  const yandexClientId = Cookies.get('_ym_uid');
  
  const [blockSecondElementFormOA, setBlockSecondElementFormOA] = useState(false); // блокирует элементы формы
  const [showSpinnerOA, setShowSpinnerOA] = useState(false); // включает/выключает спиннер
  const [blockedBtn, setBlockedBtn] = useState(false); // блокирует кнопку отправки формы
  const [successFirstRequest, setSuccessFirstRequest] = useState(false); // успешный первый запрос
  const [minMaxStep, setMinMaxStep] = useState({
    min: 0,
    max: 0,
    step: 0,
  });

  // данные get запроса calculate
  const {
    assetTypes,
    regions,
    companyActivityPeriods
  } = context?.state?.stateDirectories;

  // НАЧАЛО ПЕРВОЙ ФОРМЫ
  const schema = yup.object().shape({
    selectCategory: yup.object()
      .required('Выберите один из вариантов'),
    inputItem: yup.string()
      .required('Это поле необходимо заполнить')
      .trim()
      .max(100, 'Максимум 100 символов'),
    inputContract: yup.number()
      .typeError('Недостаточно цифр')
      .min(6, 'Минимальное число 6')
      .required('Это поле необходимо заполнить')
      .default(60),
    rangeContract: yup.number()
      .typeError('Недостаточно цифр')
      .min(6, 'Минимальное число 6')
      .required('Это поле необходимо заполнить')
      .default(60),
    inputPrice: yup.number()
      .typeError('Недостаточно цифр')
      .min(7, 'Минимальное число 7')
      .required('Это поле необходимо заполнить')
      .default(10000000),
    rangePrice: yup.number()
      .typeError('Недостаточно цифр')
      .min(7, 'Минимальное число 7')
      .required('Это поле необходимо заполнить')
      .default(10000000),
  });

  const {
    watch, // аналог state как в useState()
    setValue, // аналог setState как в useState()
    control,
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    defaultValues: schema.cast(),
    resolver: yupResolver(schema),
    // defaultValues,
    // mode: "onBlur"
    mode: "onChange"
  });

  // позволяет отслеживать значение элементов в реальном времени (аналог state в useState())
  const valueInputContract = watch('inputContract');
  const valueRangeContract = watch('rangeContract');
  const valueInputPrice = watch('inputPrice');
  const valueRangePrice = watch('rangePrice');

  // отправка первой формы
  const submitForm = async (data) => {
    context.dispatch({ type: 'STATE_LEASING_FORM', data }); // сохраняем в стор результат первой формы

    try {
      const response = await fetchHelper(`/landing/v1/schedule/calculate`, {
        method: 'POST',
        body: JSON.stringify({
          assetTypeId: data?.selectCategory?.id,
          paymentsQuantity: data?.rangeContract,
          currencyId: 1,
          assetCost: data?.rangePrice,
          advanceAmount: parseInt(data?.rangePrice / 100 * data?.rangePrepayment) || 1,
        }),
      });
      const dataRes = await response.json();
      // context.dispatch({ type: 'RESPONSE_LEASING_CALCULATE', data: dataRes }); // сохраняем в стор ответ POST запроса
      // setSuccessFirstRequest(true); // разрешаем отправку второй формы

      if (response?.status === 200) {
        context.dispatch({ type: 'RESPONSE_LEASING_CALCULATE', data: dataRes }); // сохраняем в стор ответ POST запроса
        setSuccessFirstRequest(true); // разрешаем отправку второй формы
        setShowSpinnerOA(true); // включает спиннер
      }

      if (response?.status === 400 || response?.status === 404) {
        context.dispatch({ type: 'RESPONSE_LEASING_CALCULATE_ERROR', data: dataRes });
        setShowSpinnerOA(false); // отключает спиннер
      }

      if (response?.status === 422) {
        context.dispatch({ type: 'RESPONSE_LEASING_CALCULATE_ERROR', data: {message: dataRes} });
        setShowSpinnerOA(false); // отключает спиннер
      }

      if (response?.status === 500) {
        context.dispatch({ type: 'RESPONSE_LEASING_CALCULATE_ERROR', data: dataRes });
        setShowSpinnerOA(false); // отключает спиннер
      }
    } catch (err) {
      setShowSpinnerOA(false); // отключает спиннер
      console.log('ошибка в POST запросе calculate');
      context.dispatch({ type: 'RESPONSE_LEASING_CALCULATE_ERROR', data: { message: 'К сожалению, произошла ошибка, пожалуйста, повторите попытку позже.' } });
    }
  }

  // устанавливает значения по умолчанию в инпут-рендже СТОИМОСТЬ
  useEffect(() => {
    setMinMaxStep({
      min: 1000000,
      max: 200000000,
      step: 50000,
    })
  }, [setMinMaxStep]);

  // устанавливаем значения в поля первой формы
  useEffect(() => {
    if (assetTypes) {
      setValue('selectCategory', assetTypes?.[0]);
    }
  }, [assetTypes]);
  // КОНЕЦ ПЕРВОЙ ФОРМЫ

  // НАЧАЛО ВТОРОЙ ФОРМЫ
  const schema2 = yup.object().shape({
    inputINN2: yup.string()
      .max(12, 'Максимум 12 символов')
      .matches(/^[0-9 ]*$/gmi, "Должно быть числом") // разрешены пустая строка и только цифры
      .test("0 or min 10", "Минимум 10 символов", (value) => {
        if (value?.length > 0 && value?.length < 10) return false // разрешена пустая строка и минимум 10 символов
        else return true
      }),
    inputNameOrganization2: yup.string()
      .required('Это поле необходимо заполнить')
      .trim(),
    selectTermWork2: yup.object()
      .required('Выберите один из вариантов'),
    selectLocation2: yup.object()
      .required('Выберите один из вариантов'),
    inputUser2: yup.string()
      .required('Это поле необходимо заполнить')
      .trim()
      .matches(/^[-а-яА-ЯёЁ\s]+$/, "Разрешены только кириллица и дефис"),
    inputPhone2: yup.string()
      .required('Это поле необходимо заполнить')
      .matches(/^((8|\+7)[\- ]?)?(\(?\d{3}\)?[\- ]?)?[\d\- ]{7,10}$/gmi, "Введите корректный номер телефона"),
    inputEmail2: yup.string()
      .required('Это поле необходимо заполнить')
      .email('Пожалуйста, введите корректный адрес электронной почты'),
    agree2: yup.bool()
      .oneOf([true], 'Примите согласие'),
  }); // указываются имена полей для возможности циклической обработки (типо зависимостей)

  const {
    watch: watch2, // аналог state как в useState()
    setValue: setValue2, // аналог setState как в useState()
    control: control2,
    register: register2,
    handleSubmit: handleSubmit2,
    formState: { errors: errors2, isValid: isValid2 },
  } = useForm({
    defaultValues: schema2.cast(),
    resolver: yupResolver(schema2),
    // mode: "onBlur"
    mode: "onChange"
  });

  // позволяет отслеживать значение элементов в реальном времени (аналог state в useState())
  const agreeWatch2 = watch2('agree2'); 
  const agreeSelectTermWork2 = watch2('selectTermWork2'); 

  // отправка второй формы
  const submitForm2 = async (data) => {
    // сохраняем в стор результат второй формы
    context.dispatch({
      type: 'STATE_LEASING_FORM',
      data: {
        ...context?.state?.stateLeasingForm,
        inputINN: data?.inputINN2,
        inputNameOrganization: data?.inputNameOrganization2,
        agree: data?.agree2,
        selectTermWork: data?.selectTermWork2,
        selectLocation: data?.selectLocation2,
        inputUser: data?.inputUser2,
        inputPhone: data?.inputPhone2,
        inputEmail: data?.inputEmail2,
      },
    });

    try {
      const response = await fetchHelper(`/landing/v1/schedule/post`, {
        method: 'POST',
        body: JSON.stringify({
          calculationUID: context?.state?.responseLeasingCalculate?.uid,
          assetName: context?.state?.stateLeasingForm?.selectCategory?.name,
          typeId: 2,
          customer: {
            companyName: data?.inputNameOrganization2,
            companyTIN: data?.inputINN2,
            companyActivityPeriodId: data?.selectTermWork2?.id,
            regionId: data?.selectLocation2?.id,
            person: data?.inputUser2,
            phone: data?.inputPhone2,
            email: data?.inputEmail2,
          },
          clientId: yandexClientId,
        }),
      });
      const dataRes = await response.json();
      // setTimeout(() => {
      //   setSuccessFirstRequest(false); // блокируем срабатывание useEffect который отправляет вторую форму
      //   context.dispatch({ type: 'MODAL', data: { state: false, type: 'online_application' }, });
      //   setTimeout(() => {
      //     context.dispatch({ type: 'MODAL', data: { state: true, type: 'success' }, });
      //   }, 500);
      // }, 100);

      if (response?.status === 200) {
        setTimeout(() => {
          setSuccessFirstRequest(false); // блокируем срабатывание useEffect который отправляет вторую форму
          context.dispatch({ type: 'MODAL', data: { state: false, type: 'online_application' }, });
          setTimeout(() => {
            context.dispatch({ type: 'MODAL', data: { state: true, type: 'success' }, });
          }, 500);
        }, 100);
      }

      if (response?.status === 400 || response?.status === 404) {
        context.dispatch({ type: 'RESPONSE_POST_ERROR', data: dataRes });
      }

      if (response?.status === 422) {
        context.dispatch({ type: 'RESPONSE_POST_ERROR', data: {message: dataRes} });
      }

      if (response?.status === 500) {
        context.dispatch({ type: 'RESPONSE_POST_ERROR', data: dataRes });
      }
    } catch (err) {
      console.log('ошибка в POST запросе post');
      context.dispatch({ type: 'RESPONSE_POST_ERROR', data: { message: 'К сожалению, произошла ошибка, пожалуйста, повторите попытку позже.' } });
    } finally {
      setShowSpinnerOA(false); // отключает спиннер
      setTimeout(() => {
        setBlockSecondElementFormOA(false); // разблокирует элементы формы
      }, 5000);
    }
  }

  // блокируем кнопку если чекбокс выключен
  useEffect(() => {
    if (agreeWatch2 !== undefined && !agreeWatch2) {
      setBlockedBtn(true); // блокируем кнопку
    } else setBlockedBtn(false); // разблокируем кнопку
  }, [agreeWatch2]);

  // отправляем результат второго шага после отправки первого шага
  useEffect(() => {
    if (successFirstRequest) {
      handleSubmit2((data) => submitForm2(data))(); // отправка второй формы
    }
  }, [successFirstRequest]);

  // устанавливаем значения в поля второй формы
  useEffect(() => {
    if (assetTypes) {
      setValue2('selectTermWork2', companyActivityPeriods?.[companyActivityPeriods?.length - 1]);
    }
  }, [assetTypes]);
  // КОНЕЦ ВТОРОЙ ФОРМЫ

  // по клику на крестик (закрытие модального окна)
  const hadlerClose = () => {
    onClose();
    context.dispatch({ type: 'RESPONSE_LEASING_CALCULATE_ERROR', data: '' });
  };

  return (
    <div className="content_modals_online_application">
      <div 
        className="modal_sf_wrap_close"
        onClick={hadlerClose}
      >
        <img src={require('../../../../assets/close.svg').default} alt="Закрыть" />
      </div>
      <h3 className="content_modals_online_application__title">
        Онлайн-заявка на лизинг
      </h3>

      <div className="form">
        <div className="content_modals_online_application__wrap_double_field">
          <ContainerFormElement textLabel="Предмет лизинга">
            <SelectSf
              placeholder={"Легковой автомобиль"}
              className={"select_sf"}
              classNamePrefix={"select_sf"}
              error={errors.selectCategory}
              options={assetTypes}
              control={control}
              name={"selectCategory"}
              disabled={blockSecondElementFormOA}
            >
            </SelectSf>
            {errors.selectCategory && <p className="steps_form__line__text_error">{errors.selectCategory.message}</p>}
          </ContainerFormElement>

          <ContainerFormElement textLabel="Описание предмета">
            <InputSf
              placeholder=""
              error={errors.inputItem}
              disabled={blockSecondElementFormOA}
              validation={{
                ...register("inputItem")
              }}
            />
            {errors.inputItem && <p className="steps_form__line__text_error">{errors.inputItem.message}</p>}
          </ContainerFormElement>
        </div>

        <div className="content_modals_online_application__wrap_double_field">
          <ContainerFormElement textLabel="Срок договора, мес.">
            <div className="steps_form__right_plaseholder">
              <InputRange
                min={6}
                max={60}
                maxlength={2}
                step={6}
                error={(errors.inputContract && errors.rangeContract)}
                control={control}
                valueInput={valueInputContract}
                valueRange={valueRangeContract}
                setValue={setValue}
                disabled={blockSecondElementFormOA}
                inputValidation={{
                  ...register("inputContract", {
                    valueAsNumber: true,
                  })
                }}
                rangeValidation={{
                  ...register("rangeContract",)
                }}
              />
              <div className="steps_form__right_plaseholder_text">до 60 месяцев</div>
            </div>
            {(errors.inputContract && errors.rangeContract) && <p className="steps_form__line__text_error">Минимальное число 7</p>}
          </ContainerFormElement>

          <ContainerFormElement
            textLabel="Стоимость, руб."
          >
            <InputRange
              min={minMaxStep.min}
              max={minMaxStep.max}
              step={minMaxStep.step}
              error={(errors.inputPrice && errors.rangePrice)}
              control={control}
              valueInput={valueInputPrice}
              valueRange={valueRangePrice}
              setValue={setValue}
              disabled={blockSecondElementFormOA}
              inputValidation={{
                ...register("inputPrice", {
                  valueAsNumber: true,
                })
              }}
              rangeValidation={{
                ...register("rangePrice",)
              }}
            />
            {(errors.inputPrice && errors.rangePrice) && <p className="steps_form__line__text_error">Минимальное число 7</p>}
          </ContainerFormElement>
        </div>
      </div>
      
      <div className="form">
        <div className="content_modals_online_application__line" />
        <h5 className="content_modals_online_application__cell_title">Информация о компании</h5>

        <div className="content_modals_online_application__wrap_double_field_long">
          <ContainerFormElement
            textLabel="ИНН"
            showIcon
            messageTooltip={`
              Данная информация собирается для ускорения рассмотрения заявки 
              и подготовки индивидуального предложения.
            `}
          >
            <InputSf
              placeholder=""
              error={errors2.inputINN2}
              maxlength={12}
              onlyNum
              setValue={setValue2}
              disabled={blockSecondElementFormOA}
              validation={{
                ...register2("inputINN2")
              }}
            />
            {errors2.inputINN2 && <p className="steps_form__line__text_error">{errors2.inputINN2.message}</p>}
          </ContainerFormElement>

          <ContainerFormElement textLabel="Наименование организации">
            <InputSf
              placeholder="ООО «Компания»"
              error={errors2.inputNameOrganization2}
              disabled={blockSecondElementFormOA}
              validation={{
                ...register2("inputNameOrganization2")
              }}
            />
            {errors2.inputNameOrganization2 && <p className="steps_form__line__text_error">{errors2.inputNameOrganization2.message}</p>}
          </ContainerFormElement>
        </div>

        <div className="content_modals_online_application__wrap_double_field_long">
          <ContainerFormElement
            textLabel="Срок работы на рынке"
            showIcon={agreeSelectTermWork2?.id === 1}
            messageTooltip="Возможно, вам потребуется поручительство"
          >
            <SelectSf
              placeholder={"До 1 года"}
              className={"select_sf"}
              classNamePrefix={"select_sf"}
              options={companyActivityPeriods}
              control={control2}
              error={errors2.selectTermWork2}
              name={"selectTermWork2"}
              disabled={blockSecondElementFormOA}
            >
            </SelectSf>
            {errors2.selectTermWork2 && <p className="steps_form__line__text_error">{errors2.selectTermWork2.message}</p>}
          </ContainerFormElement>

          <ContainerFormElement
            textLabel="Местоположение головного офиса"
          >
            <SelectSf
              placeholder={"Местоположение"}
              className={"select_sf"}
              classNamePrefix={"select_sf"}
              error={errors2.selectLocation2}
              options={regions}
              control={control2}
              name={"selectLocation2"}
              isSearchable
              disabled={blockSecondElementFormOA}
            >
            </SelectSf>
            {errors2.selectLocation2 && <p className="steps_form__line__text_error">{errors2.selectLocation2.message}</p>}
          </ContainerFormElement>
        </div>

        <div className="content_modals_online_application__wrap_double_field_reverse_long">
          <ContainerFormElement textLabel="Контактное лицо">
            <InputSf
              placeholder="Иванов Сергей Сергеевич"
              error={errors2.inputUser2}
              disabled={blockSecondElementFormOA}
              validation={{
                ...register2("inputUser2")
              }}
            />
            {errors2.inputUser2 && <p className="steps_form__line__text_error">{errors2.inputUser2.message}</p>}
          </ContainerFormElement>

          <ContainerFormElement textLabel="Контактный телефон">
            <CustomInputMask
              control={control2}
              error={errors2.inputPhone2}
              name={"inputPhone2"}
              disabled={blockSecondElementFormOA}
            />
            {errors2.inputPhone2 && <p className="steps_form__line__text_error">{errors2.inputPhone2.message}</p>}
          </ContainerFormElement>
        </div>

        <div className="content_modals_online_application__wrap_double_field_reverse_long last">
          <ContainerFormElement textLabel="Адрес эл. почты">
            <InputSf
              placeholder="ivanov@mail.ru"
              error={errors2.inputEmail2}
              disabled={blockSecondElementFormOA}
              validation={{
                ...register2("inputEmail2")
              }}
            />
            {errors2.inputEmail2 && <p className="steps_form__line__text_error">{errors2.inputEmail2.message}</p>}
          </ContainerFormElement>
        </div>

        <div className="content_modals_online_application__wrap_checkbox">
          <CheckboxSf
            label="Нажимая кнопку «Продолжить», я даю согласие на обработку моих персональных данных <br />ООО «ДельтаЛизинг» согласно <a href='https://site.deltaleasing.ru/confidentiality' class='personal_link' target='_blank'>Политике конфиденциальности</a>."
            check={agreeWatch2}
            disabled={blockSecondElementFormOA}
            validation={{
              ...register2("agree2")
            }}
          />
          {errors2.agree2 && <p className="steps_form__line__text_error">{errors2.agree2.message}</p>}
        </div>

        {context?.state?.stateDirectoriesError?.message
          && (
            <div className="steps_form__response_error">
              {context?.state?.stateDirectoriesError?.message}
            </div>
          )}
        {context?.state?.responseLeasingCalculateError?.message
          && (
            <div className="steps_form__response_error">
              {context?.state?.responseLeasingCalculateError?.message}
            </div>
          )}
        {context?.state?.responsePostError?.message
          && (
            <div className="steps_form__response_error">
              {context?.state?.responsePostError?.message}
            </div>
          )}

        <div className="content_modals_online_application__wrap_btn_and_spinner">
          <div className="content_modals_online_application__wrap_btn">
            <ButtonSf
              typeBtn='second'
              disabled={!isValid || !isValid2 || blockedBtn}
              onClick={() => {
                setBlockSecondElementFormOA(true); // блокирует элементы формы
                setShowSpinnerOA(true); // включает спиннер
                setBlockedBtn(true); // блокирует кнопку
                handleSubmit((data) => submitForm(data))(); // запускает отправку первой формы и второй
              }}
            >
              Отправить заявку
            </ButtonSf>
          </div>

          {showSpinnerOA
            && (
              <div className="content_modals_online_application__wrap_spinner">
                <img src={require('../../../../assets/spinner.svg').default} alt="Раскрыть" />
              </div>
            )}
        </div>
      </div>
    </div>
  );
};

export default ContentModalsOnlineApplication;