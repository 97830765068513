import React, { useState, useEffect, useRef } from 'react';
import './ModalSf.css';
import Modal from 'react-modal';
import { CSSTransition } from 'react-transition-group';
import ContentModalsPersonalOffer from './contentModals/ContentModalsPersonalOffer/ContentModalsPersonalOffer';
import ContentModalsSpecialOffer from './contentModals/ContentModalsSpecialOffer/ContentModalsSpecialOffer';
import ContentModalsOnlineApplication from './contentModals/ContentModalsOnlineApplication/ContentModalsOnlineApplication';
import ContentModalsAuth from './contentModals/ContentModalsAuth/ContentModalsAuth';
import ContentModalsSuccess from './contentModals/ContentModalsSuccess/ContentModalsSuccess';
import {
  disablePageScroll, enablePageScroll, clearQueueScrollLocks,
} from 'scroll-lock';

Modal.setAppElement('#root');

const ModalSf = ({
  open = false,
  handleClose = () => {},
  typeModal = '',
}) => {
  const [refModalOverlay, setRefModalOverlay] = useState(null);

  // вычисляем клиентскую высоту окна браузера
  const [windowHeight, setWindowWidth] = useState(window.innerHeight);
  useEffect(() => {
    const handlHeight = () => setWindowWidth(window.innerHeight);
    window.addEventListener('resize', handlHeight);
    return () => {
      window.removeEventListener('resize', handlHeight);
    }
  });

  // вычисляем высоту обертки контента у модального окна
  const ref_content_modal = useRef(null);
  const [contentModalHeight, setContentModalHeight] = useState(null);

  useEffect(() => {
    if (open) {
      const timer = setTimeout(() => {
        setContentModalHeight(ref_content_modal.current.scrollHeight)
      }, 300);
      return () => clearTimeout(timer);
    }
  });

  // отключает скролл у body тем самым предотвращает дерганье экрана
  useEffect(() => {
    // refModalOverlay помещаем как аргумент для того, что бы в мобилке работал скролл
    if (open) disablePageScroll(refModalOverlay);
    if (!open) {
      const timer2 = setTimeout(() => {
        clearQueueScrollLocks(refModalOverlay);
        enablePageScroll(refModalOverlay);
      }, 300);
      return () => clearTimeout(timer2);
    }
  }, [open, refModalOverlay]);

  // содержит стили для обертки контента
  const typeModalContent = () => {
    switch (typeModal) {
      case 'personal_offer': return 'modal_sf__small_size_contant'
      case 'special_offer': return 'modal_sf__small_size_contant'
      case 'online_application': return 'modal_sf__big_size_contant'
      case 'auth': return 'modal_sf__small_size_contant'
      case 'success': return 'modal_sf__small_size_contant'

      default: return 'modal_sf__small_size_contant';
    }
  };

  // содержит центральные контенты модального окна
  const typeModalSwitch = () => (
    <>
      {typeModal === 'personal_offer' && <ContentModalsPersonalOffer />}
      {typeModal === 'special_offer' && <ContentModalsSpecialOffer onClose={handleClose} />}
      {typeModal === 'online_application' && <ContentModalsOnlineApplication onClose={handleClose}/>}
      {typeModal === 'auth' && <ContentModalsAuth />}
      {typeModal === 'success' && <ContentModalsSuccess onClose={handleClose} />}
    </>
  );

  return (
    <div className="modal_sf">
      <CSSTransition
        in={open}
        timeout={100}
      >
        <Modal
          closeTimeoutMS={0}
          isOpen={open}
          overlayRef={(node) => (setRefModalOverlay(node))}
          // onRequestClose={handleClose}
          overlayClassName={`ReactModal__Overlay ${contentModalHeight > windowHeight ? 'scroll-active' : ''}`}
          className={`modal_sf__wrap_content ${typeModalContent()}`}
        >
          <div className="modal_sf__content" ref={ref_content_modal}>
            {typeModalSwitch()}
          </div>
        </Modal>
      </CSSTransition>
    </div>
  );
};

export default ModalSf;