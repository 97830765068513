import React from 'react';
import './InputSf.css';

const InputSf = ({
  className = '',
  placeholder = '',
  type = 'text',
  validation = {},
  error = false,
  maxlength,
  disabled = false,
  onlyNum = false,
  setValue = () => {},
  onClick = () => {},
}) => {

  return (
    <input
      className={`input_sf ${className} ${error ? 'error' : ''}`}
      type={type}
      placeholder={placeholder}
      maxLength={maxlength}
      disabled={disabled}
      onClick={onClick}
      {...validation}
      onBlur={(e) => {
        // создает возможность использовать только цифры и создавать диапазон при этом можно 
        // редактировать минимальное число и если оно меньше чем дозволено, то при потери фокуса
        // число становится минимальным
        if (onlyNum) {
          let value = +e.target.value.replace(/\D/g, '') || '';
          validation.onChange(e)
          setValue(validation.name, value);
        }
        validation.onChange(e)
        setValue(validation.name, e.target.value);
      }}
      onChange={(e) => { // используется вместе с onBlur, что бы при редактировании минималдьного числа не появлялся NaN
        if (onlyNum) {
          let value = +e.target.value.replace(/\D/g, '') || '';
          validation.onChange(e)
          setValue(validation.name, value);
        }
        validation.onChange(e)
        setValue(validation.name, e.target.value);
      }}
    />
  );
};

export default InputSf;