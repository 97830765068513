import React from 'react';
import './ButtonSf.css';

const ButtonSf = ({
  typeBtn = 'first',
  type = 'button',
  children,
  disabled = false,
  iconLeft = false,
  iconName = '',
  onClick = () => {},
}) => {

  return (
    <button 
      className={`button ${typeBtn}`}
      type={type}
      disabled={disabled}
      onClick={onClick}
    >
      {iconLeft
        && (
          <div className='button__icon_left'>
            <img src={`${iconName}`} alt="Иконка"/>
          </div>
        )}
      
      <div className='button__text'>
        {children}
      </div>
    </button>
  );
};

export default ButtonSf;