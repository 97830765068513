import React from 'react';
import { Link } from 'react-router-dom';
import './LinkSf.css';

const LinkSf = ({
  children,
  to = '',
  classNameCustom = '',
}) => {
  return (
    <div className={`link_sf ${classNameCustom}`}>
      <Link to={to}>
        {children}
      </Link>
    </div>
  );
};

export default LinkSf;