import React from 'react';
import './GroupRadioButton.css';

const GroupRadioButton = ({
  data = [],
  value = '',
  validation = {},
  disabled = false,
}) => {

  return (
    <div>
      {data.map((item, index) =>
        <label key={index} className="group_radio_button">
          <input
            {...validation}
            type="radio"
            value={item.id}
            checked={value === item.id}
            className="group_radio_button__input"
            disabled={disabled}
          />
          <div className={`group_radio_button__dot ${value === item.id ? 'active' : ''}`}></div>
          <div className="group_radio_button__text">{item.name}</div>
        </label>
      )}
    </div>
  )
}

export default GroupRadioButton;