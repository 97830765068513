import React, { useReducer } from 'react';
import { Routes, Route } from 'react-router-dom';
import ModalSf from './components/ModalSf/ModalSf';
import Main from './pages/Main/Main';
import Insightgroup from './pages/Insightgroup/Insightgroup';

import { Context } from './globalState/context/context';
import { reducer, initialState } from './globalState/reducer/reducer.js';

function App() {
  const [state, dispatch] = useReducer(reducer, initialState);
  
  return (
    <Context.Provider value={{ state, dispatch }}>
      <Routes>
        <Route path="/" element={<Main/>} />
        <Route path="/insightgroup" element={<Insightgroup/>} />
        <Route path="*" element={<div>страница не найдена</div>} />
      </Routes>
      

      <ModalSf
        typeModal={state.stateModel.type}
        open={state.stateModel.state}
        handleClose={() => {
          dispatch({
            type: 'MODAL',
            data: {
              state: false,
              type: ''
            },
          })
        }}
      />
    </Context.Provider>
  );
}

export default App;
