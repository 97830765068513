import React from 'react';
import './ContainerFormElement.css';
import Tooltip from '../Tooltip/Tooltip';

const ContainerFormElement = ({
  children,
  textLabel = '',
  className = '',
  classNameInner = '',
  showIcon = false,
  necessarily = false,
  messageTooltip = '',
}) => {
  return (
    <div className={`container_form_element ${className}`}>
      <div className={`container_form_element__wrap_label ${classNameInner}`}>
        {showIcon && 
          <Tooltip messageTooltip={messageTooltip}>
            <img
              className="container_form_element__icon"
              src={require('../../../assets/info.svg').default}
              alt="Подсказка"
            />
          </Tooltip>
        }
        <div className="container_form_element__label">{textLabel}</div>
        {necessarily &&
          <img
            className="container_form_element__red_star"
            src={require('../../../assets/red-star.svg').default}
            alt="Обязательно"
          />
        }
      </div>
      {children}
    </div>
  );
};

export default ContainerFormElement;