/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-useless-escape */
import React, { useState, useEffect, useContext } from 'react';
import './ContentModalsAuth.css';
import ContainerFormElement from '../../../UI/ContainerFormElement/ContainerFormElement';
import InputSf from '../../../UI/InputSf/InputSf';
import ButtonSf from '../../../UI/ButtonSf/ButtonSf';
import { convertSecondsToMinutes } from '../../../../helpers/common-helper';
import CustomInputMask from '../../../UI/CustomInputMask/CustomInputMask';
import { fetchHelperAuth } from '../../../../helpers/api-helper';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { Context } from '../../../../globalState/context/context';

const ContentModalsAuth = () => {
  const context = useContext(Context);
  const [shapeChange, setShapeChange] = useState(true); // меняет формы---логин пароль на смс
  const [shapeChange2, setShapeChange2] = useState(true); // меняет формы---email на смс
  const [shapeChangeLoginToEmail, setShapeChangeLoginToEmail] = useState(false); // меняет формы---логин пароль на email
  const [blockedUser, setBlockedUser] = useState(false); // пользователь заблокирован
  const [successCodeEmail, setSuccessCodeEmail] = useState(false); // успешная отправка кода после Email

  const [blockBtnLoginPassword, setBlockBtnLoginPassword] = useState(false); // блокирует кнопку логин-пароль
  const [errorLoginPassword, setErrorBtnLoginPassword] = useState(false); // выводит ошибку для логин-пароль
  const [confirmationCode, setConfirmationCode] = useState(null); // содержит ответ смс с кодом
  const [confirmationCodeEmail, setConfirmationCodeEmail] = useState(null); // содержит ответ смс с кодом после ввода почты

  const [blockBtnSms, setBlockBtnSms] = useState(false); // блокирует кнопку sms
  const [errorSms, setErrorBtnSms] = useState(false); // выводит ошибку для sms
  const [errorSmsForInput, setErrorBtnSmsForInput] = useState(false); // выводит ошибку для sms под инпутом


  // начало таймера
  let intervalId = null;
  let timeCountDown = 30;
  const [startTimer, setStartTimer] = useState(false); // запускает таймер
  const [timeLeft, setTimeLeft] = useState(timeCountDown);
  useEffect(() => {
    if (!timeLeft) {
      clearInterval(intervalId);
      setStartTimer(false);
      setTimeLeft(timeCountDown);
    }

    if (startTimer) {
      intervalId = setInterval(() => setTimeLeft(timeLeft - 1), 1000);
      return () => clearInterval(intervalId);
    };
  }, [timeLeft, startTimer, setTimeLeft]);
  // конец таймера

  // НАЧАЛО ДЛЯ ФОРМЫ LOGIN PASSWORD
  const schema = yup.object().shape({
    inputPhone: yup.string()
      .required('Это поле необходимо заполнить')
      .matches(/^((8|\+7)[\- ]?)?(\(?\d{3}\)?[\- ]?)?[\d\- ]{7,10}$/gmi, "Введите корректный номер телефона"),
    inputPassword: yup.string()
      .required('Это поле необходимо заполнить')
      .max(40, 'Максимум 40 символов'),
  });

  const {
    watch,
    register,
    handleSubmit,
    control,
    formState: { errors, isDirty  },
  } = useForm({
    defaultValues: schema.cast(),
    resolver: yupResolver(schema),
    mode: "onBlur"
  });

  const valueInputPhone = watch('inputPhone');
  
  // отправка формы логина и пароля
  const submitForm = async (data) => {
    setBlockBtnLoginPassword(true); // блокирует кнопку

    let body = new FormData();
    body.append('phone_number', `+${data?.inputPhone.replace(/\D/gi, '')}`);
    body.append('password', data?.inputPassword);

    try {
      const response = await fetchHelperAuth(`/client/v1/auth?action=code-send&_lang=ru-RU`, {
        method: 'POST',
        body
      });
      const dataRes = await response.json();

      if (response?.status === 200) {
        setShapeChange(false);
        setConfirmationCode(dataRes?.code);
      }

      // когда пользователь заблокирован
      if (response?.status === 423) {
        setBlockedUser(true);
      }

      // если ошибка
      if (response?.status === 422) {
        setErrorBtnLoginPassword(dataRes?.[0]?.message); // записываем полученную ошибку
      };
      
      setBlockBtnLoginPassword(false);
    } catch (err) {
      console.log('ошибка в POST запросе auth?action=code-send');
    }
  }
  // КОНЕЦ ДЛЯ ФОРМЫ LOGIN PASSWORD

  
  // НАЧАЛО ДЛЯ ФОРМЫ EMAIL
  const schema2 = yup.object().shape({
    inputEmail2: yup.string()
      .required('Это поле необходимо заполнить')
      .email('Пожалуйста, введите корректный адрес электронной почты'),
  });

  const {
    watch: watch2,
    register: register2,
    handleSubmit: handleSubmit2,
    formState: { errors: errors2, isDirty: isDirty2 },
  } = useForm({
    defaultValues: schema2.cast(),
    resolver: yupResolver(schema2),
    mode: "onBlur"
  });

  const valueInputEmail2 = watch2('inputEmail2');

  // отправка формы email
  const submitForm2 = async (data) => {

    let body = new FormData();
    body.append('email', data?.inputEmail2);
    
    try {
      const response = await fetchHelperAuth(`/client/v1/recovery?action=code-send&_lang=ru-RU`, {
        method: 'POST',
        body
      });
      const dataRes = await response.json();

      if (response?.status === 200) {
        setShapeChange2(false);
        setConfirmationCodeEmail(dataRes?.code);
      }

      // если ошибка
      if (response?.status === 422) {};

      setBlockBtnLoginPassword(false);
    } catch (err) {
      console.log('ошибка в POST запросе recovery?action=code-send');
    }
  }
  // КОНЕЦ ДЛЯ ФОРМЫ EMAIL


  // НАЧАЛО ДЛЯ ФОРМЫ SMS
  const schema3 = yup.object().shape({
    inputSms3: yup.string()
      .required('Это поле необходимо заполнить')
  });

  const {
    register: register3,
    setValue: setValue3,
    handleSubmit: handleSubmit3,
    formState: { errors: errors3, isDirty: isDirty3 },
  } = useForm({
    defaultValues: schema3.cast(),
    resolver: yupResolver(schema3),
    mode: "onBlur"
  });

  // отправка формы sms после логи и пароля
  const submitForm3 = async (data) => {
    setBlockBtnSms(true); // блокирует кнопку

    let body = new FormData();
    if (valueInputPhone) body.append('phone_number', `+${valueInputPhone.replace(/\D/gi, '')}`);
    body.append('code', data?.inputSms3);

    try {
      const response = await fetchHelperAuth(`/client/v1/auth?action=code-validate&_lang=ru-RU`, {
        method: 'POST',
        body
      });
      const dataRes = await response.json();

      if (response?.status === 200) {
        setErrorBtnSms(false); // убираем ошибку
        setErrorBtnSmsForInput(false); // убираем ошибку

        // записываем токены
        localStorage.setItem('authToken', dataRes.access_token);
        localStorage.setItem('authTokenExpire', dataRes.access_token_expire);
        localStorage.setItem('refreshToken', dataRes.refresh_token);

        // открываем страницу в новой вкладке
        window.open('https://online.deltaleasing.ru', '_blank');

        // закрываем модальное окно авторизации
        setTimeout(() => {
          context.dispatch({ type: 'MODAL', data: { state: false, type: 'auth' }, });
        }, 100);
      }

      // когда пользователь заблокирован
      if (response?.status === 423) {
        setBlockedUser(true);
      }

      // когда ошибка
      if (response?.status === 422) {
        setErrorBtnSms(dataRes);
        setErrorBtnSmsForInput(true);
      };

      setBlockBtnSms(false); // разблокирует кнопку
    } catch (err) {
      console.log('ошибка в POST запросе action=code-validate');
    }
  }
  // КОНЕЦ ДЛЯ ФОРМЫ SMS


  // НАЧАЛО ДЛЯ ФОРМЫ SMS ПОСЛЕ ВВОДА EMAIL
  const schema4 = yup.object().shape({
    inputSms4: yup.string()
      .required('Это поле необходимо заполнить')
  });

  const {
    register: register4,
    setValue: setValue4,
    handleSubmit: handleSubmit4,
    formState: { errors: errors4, isDirty: isDirty4 },
  } = useForm({
    defaultValues: schema4.cast(),
    resolver: yupResolver(schema4),
    mode: "onBlur"
  });


  // отправка формы email
  const submitForm4 = async (data) => {
    let body = new FormData();
    body.append('email', valueInputEmail2);
    body.append('code', data?.inputSms4);

    try {
      const response = await fetchHelperAuth(`/client/v1/recovery?action=code-validate&_lang=ru-RU`, {
        method: 'POST',
        body
      });
      const dataRes = await response.json();

      if (response?.status === 200) {
        setErrorBtnSms(false); // убираем ошибку
        setErrorBtnSmsForInput(false); // убираем ошибку
        setSuccessCodeEmail(true); // показываем, что код был отправлен
      }

      // когда пользователь заблокирован
      if (response?.status === 423) {
        setBlockedUser(true);
      }

      // когда ошибка
      if (response?.status === 422) {
        setErrorBtnSms(dataRes);
        setErrorBtnSmsForInput(true);
      };

    } catch (err) {
      console.log('ошибка в POST запросе recovery?action=code-validate');
    }
  }
  // КОНЕЦ ДЛЯ ФОРМЫ SMS ПОСЛЕ ВВОДА EMAIL

  return (
    <div className="content_modals_auth">
      {/* пользователь не заблокирован */}
      {!blockedUser
        && (
          <>
            <h3 className="content_modals_auth__title">
              Добро пожаловать в Онлайн-офис «ДельтаЛизинг»!
            </h3>

            <div className={`content_modals_auth__form_login_password_email ${shapeChangeLoginToEmail ? 'active' : ''}`}>
              {/* поля для ввода логина и пароля */}
              {shapeChange
                && (
                  <form onSubmit={handleSubmit(submitForm)} className="form">
                    <ContainerFormElement textLabel="Номер телефона">
                      <CustomInputMask
                        control={control}
                        error={errors.inputPhone}
                        name={"inputPhone"}
                      />
                      {errors.inputPhone && <p className="steps_form__line__text_error">{errors.inputPhone.message}</p>}
                    </ContainerFormElement>

                    <div className="content_modals_auth__wrap_container_fom_element">
                      <ContainerFormElement textLabel="Пароль">
                        <InputSf
                          placeholder=""
                          type="password"
                          onClick={() => setErrorBtnLoginPassword(false)}
                          error={errors.inputPassword}
                          validation={{
                            ...register("inputPassword")
                          }}
                        />
                        {errors.inputPassword && <p className="steps_form__line__text_error">{errors.inputPassword.message}</p>}
                        {errorLoginPassword && <div className='steps_form__line__text_error'>{errorLoginPassword}</div>}
                      </ContainerFormElement>
                    </div>

                    <div className="content_modals_auth__wrap_container_fom_element">
                      <div className="content_modals_auth__wrap_double_field">
                        <div className="content_modals_auth__wrap_bnt_inter">
                          <ButtonSf
                            typeBtn='second'
                            type="submit"
                            disabled={!isDirty || blockBtnLoginPassword}
                            onClick={() => {
                              // сбрасываем ошибки для поля ввода смс, если попадаем на него второй раз
                              setErrorBtnSms(false);
                              setErrorBtnSmsForInput(false);
                              setValue3('inputSms3', '');
                            }}
                          >
                            Войти
                          </ButtonSf>
                        </div>

                        <div className="content_modals_auth__wrap_link_password">
                          <div
                            href="/"
                            className="content_modals_auth__link_password"
                            onClick={() => setShapeChangeLoginToEmail(true)}
                          >
                            Забыли пароль?
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="content_modals_auth__line" />

                    <div className="content_modals_auth__text">
                      Для регистрации в онлайн офисе вам нужно заполнить заявку на лизинг
                    </div>

                    <div className="content_modals_auth__wrap_btn">
                      <ButtonSf
                        typeBtn='first'
                        onClick={() => context.dispatch({ type: 'MODAL', data: { state: true, type: 'online_application' }, })}
                      >
                        Отправить заявку
                      </ButtonSf>
                    </div>
                  </form>
                )}

              {/* поле для ввода смс кода после логина и пароля */}
              {!shapeChange
                && (
                  <form
                    onSubmit={handleSubmit3(submitForm3)}
                    className="form"
                  >

                    {confirmationCode && <div>ПОЛУЧЕННЫЙ КОД --- {confirmationCode}</div>}

                    <div className="content_modals_auth__wrap_container_fom_element">
                      <ContainerFormElement textLabel="Введите код подтверждения, отправленный Вам в SMS:">
                        <InputSf
                          error={errors3.inputSms3}
                          maxlength={4}
                          onlyNum
                          setValue={setValue3}
                          onClick={() => setErrorBtnSmsForInput(false)}
                          validation={{
                            ...register3("inputSms3")
                          }}
                        />
                        {errors3.inputSms3 && <p className="steps_form__line__text_error">{errors3.inputSms3.message}</p>}
                        {errorSmsForInput && <p className="steps_form__line__text_error">Неверный e-mail или код</p>}
                      </ContainerFormElement>
                    </div>

                    {errorSms?.tries_more
                      && (
                        <>
                          <p className="steps_form__line__sms_description">
                            У вас осталось {errorSms?.tries_more} попыток ввести код,
                            после чего аккаунт будет заблокирован.
                            <br />
                            <br />
                            Вы сможете запросить код повторно через
                            {' '}
                            {convertSecondsToMinutes(timeLeft)}
                            {' '}
                            сек
                          </p>

                          <button
                            type='button'
                            className="steps_form__line__request_again"
                            disabled={timeLeft < timeCountDown}
                            onClick={() => {
                              setStartTimer(true); // запускаем таймер
                              handleSubmit((data) => submitForm(data))(); // запускаем повторно запрос на получение смс
                            }}
                          >
                            Запросить повторно
                          </button>
                        </>
                      )}

                    <div className="content_modals_auth__wrap_double_field">
                      <div className="content_modals_auth__wrap_bnt_inter">
                        <ButtonSf
                          typeBtn='second'
                          onClick={() => setShapeChange(true)}
                        >
                          назад
                        </ButtonSf>
                      </div>

                      <div className="content_modals_auth__wrap_bnt_inter">
                        <ButtonSf
                          typeBtn='first'
                          type="submit"
                          disabled={!isDirty3 || blockBtnSms || timeLeft < timeCountDown}
                        >
                          подтвердить
                        </ButtonSf>
                      </div>
                    </div>
                  </form>
                )}
            </div>
              
            <div className={`content_modals_auth__form_confirmation_code ${!shapeChangeLoginToEmail ? 'active' : ''}`}>
              {/* поле для ввода почты */}
              {shapeChange2
                && (
                  <form onSubmit={handleSubmit2(submitForm2)} className="form">
                    <div className="content_modals_auth__wrap_container_fom_element">
                      <ContainerFormElement textLabel="Адрес эл. почты">
                        <InputSf
                          placeholder="ivanov@mail.ru"
                          error={errors2.inputEmail2}
                          validation={{
                            ...register2("inputEmail2")
                          }}
                        />
                        {errors2.inputEmail2 && <p className="steps_form__line__text_error">{errors2.inputEmail2.message}</p>}
                      </ContainerFormElement>
                    </div>

                    <div className="content_modals_auth__wrap_double_field">
                      <div className="content_modals_auth__wrap_bnt_inter">
                        <ButtonSf
                          typeBtn='second'
                          type="submit"
                          disabled={!isDirty2}
                          onClick={() => {
                            // сбрасываем ошибки для поля ввода смс, если попадаем на него второй раз
                            setErrorBtnSms(false);
                            setErrorBtnSmsForInput(false);
                            setValue4('inputSms4', '');
                          }}
                        >
                          Восстановить
                        </ButtonSf>
                      </div>

                      <div className="content_modals_auth__wrap_link_password">
                        <div
                          href="/"
                          className="content_modals_auth__link_password"
                          onClick={() => setShapeChangeLoginToEmail(false)}
                        >
                          Вспомнили пароль?
                        </div>
                      </div>
                    </div>
                  </form>
                )}
              
              {/* поле для ввода смс кода после email */}
              {!shapeChange2
                && (
                  <>
                    {/* поле для ввода смс */}
                    {!successCodeEmail
                      && (
                        <form
                          onSubmit={handleSubmit4(submitForm4)}
                          className="form"
                        >
                          {confirmationCodeEmail && <div>ПОЛУЧЕННЫЙ КОД --- {confirmationCodeEmail}</div>}

                          <div className="content_modals_auth__wrap_container_fom_element">
                            <ContainerFormElement textLabel="Введите код подтверждения, отправленный Вам в SMS:">
                              <InputSf
                                error={errors4.inputSms4}
                                maxlength={4}
                                onlyNum
                                setValue={setValue4}
                                onClick={() => setErrorBtnSmsForInput(false)}
                                validation={{
                                  ...register4("inputSms4")
                                }}
                              />
                              {errors4.inputSms4 && <p className="steps_form__line__text_error">{errors4.inputSms4.message}</p>}
                              {errorSmsForInput && <p className="steps_form__line__text_error">Неверный e-mail или код</p>}
                            </ContainerFormElement>
                          </div>

                          {errorSms?.tries_more
                            && (
                              <>
                                <p className="steps_form__line__sms_description">
                                  У вас осталось {errorSms?.tries_more} попыток ввести код,
                                  после чего аккаунт будет заблокирован.
                                  <br />
                                  <br />
                                  Вы сможете запросить код повторно через
                                  {' '}
                                  {convertSecondsToMinutes(timeLeft)}
                                  {' '}
                                  сек
                                </p>

                                <button
                                  type='button'
                                  className="steps_form__line__request_again"
                                  disabled={timeLeft < timeCountDown}
                                  onClick={() => {
                                    setStartTimer(true); // запускаем таймер
                                    handleSubmit2((data) => submitForm2(data))(); // запускаем повторно запрос на получение смс после ввода почты
                                  }}
                                >
                                  Запросить повторно
                                </button>
                              </>
                            )}

                          <div className="content_modals_auth__wrap_double_field">
                            <div className="content_modals_auth__wrap_bnt_inter">
                              <ButtonSf
                                typeBtn='second'
                                onClick={() => setShapeChange2(true)}
                              >
                                назад
                              </ButtonSf>
                            </div>

                            <div className="content_modals_auth__wrap_bnt_inter">
                              <ButtonSf
                                typeBtn='first'
                                type="submit"
                                disabled={!isDirty4 || blockBtnSms || timeLeft < timeCountDown}
                              >
                                подтвердить
                              </ButtonSf>
                            </div>
                          </div>
                        </form>
                      )}

                    {/* контент об успешном восстановлении пароля */}
                    {successCodeEmail
                      && (
                        <div className="content_modals_auth__success_confirmation">
                          <img
                            className="content_modals_auth__success_confirmation_img"
                            src={require('../../../../assets/notification_ready.svg').default}
                            alt="Заблокирован"
                          />
                          <div className="content_modals_auth__success_confirmation_descriotion">
                            На ваш e-mail, указанный в Договоре, отправлен временный пароль.
                          </div>
                          <div
                            className="content_modals_auth__success_confirmation_inter"
                            onClick={() => {
                              setSuccessCodeEmail(false); // возвращается к инпуту отправки кода (на случай повторного захода)
                              setShapeChangeLoginToEmail(false); // открываем контент с логином и паролем
                            }}
                          >
                            Войти
                          </div>
                        </div>
                      )}
                  </>
                )}
            </div>
          </>
        )}
      
      {/* конетент о заблокированном пользователе */}
      {blockedUser
        && (
          <div className="content_modals_auth__blocked_content">
            <img
              className="content_modals_auth__blocked_img"
              src={require('../../../../assets/blocked.svg').default}
              alt="Заблокирован"
            />
            <div  className="content_modals_auth__blocked_title">
              Ваш аккаунт заблокирован
            </div>

            <div className="content_modals_auth__blocked_description">
              Восстановить доступ в Личный кабинет DeltaЛизинг Вы можете по следующему телефону
            </div>

            <div className="content_modals_auth__blocked_big_wrap_country">
              <div className="content_modals_auth__blocked_wrap_country">
                <a href="tel:+7 (800) 200 01 80" className="content_modals_auth__blocked_phone">
                  +7 (800) 200 01 80
                </a>

                <div className="content_modals_auth__blocked_country">
                  ПО РОССИИ
                </div>
              </div>
            </div>
          </div>
        )}
    </div>
  );
};

export default ContentModalsAuth;