import React from 'react';
import InputMask from 'react-input-mask';
import { Controller } from "react-hook-form";
import './CustomInputMask.css';

const CustomInputMask = ({
  control,
  error = false,
  name = '',
  disabled = false,
}) =>{
  return (
    <Controller
      name={name}
      isClearable
      control={control}
      render={({ field }) => (
        <InputMask
          {...field}
          className={`custom_input_mask ${error ? 'error' : ''}`}
          mask="+7 999 999 99 99"
          placeholder='+7'
          maskChar="_" //пустая строка или null что бы не было никаких знаков заполнения
          disabled={disabled}
        />
      )}
    />
  );
}

export default CustomInputMask;

