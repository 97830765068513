import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { scroller } from 'react-scroll';
import { Context } from '../../globalState/context/context';
import './MobileMenu.css';

const MobileMenu = ({
  state = {},
}) => {
  const context = useContext(Context);
  let navigate = useNavigate();

  // закрывает мобильное меню
  const closeMobileMenuHandler = () => {
    context.dispatch({ type: 'MOBILE_MENU', data: false });
  }

  // открывает модальное окно ОТПРАВИТЬ ЗАЯВКУ
  const openModalRequestHandler = () => {
    context.dispatch({ type: 'MODAL', data: { state: true, type: 'online_application' }, })
    closeMobileMenuHandler();
  }

  // перемещение к якорям
  const handleScroll = (target) => {
    navigate('/');
    closeMobileMenuHandler();

    setTimeout(() => {
      scroller.scrollTo(target, {
        duration: 500,
        delay: 10,
        offset: -120,
        smooth: 'easeInOutQuart'
      });
    }, 200);
  };

  const arrNav = [
    { label: 'Лизинговые решения', click: 'unique-solutions' },
    { label: 'Калькулятор лизинга', click: 'calculator' },
    { label: 'Новости', link: 'https://site.deltaleasing.ru/news' },
    { label: 'Бизнес-истории', link: 'https://site.deltaleasing.ru/otzyvy' },
    { label: 'Акции и партнёры', link: 'https://site.deltaleasing.ru/partners' },
    { label: 'Контакты', link: 'https://site.deltaleasing.ru/contacts' },
  ]

  return (
    <div className={`mobile_menu ${state.stateMobileMenu ? 'active' : ''}`}>
      <div className="mobile_menu__wrap_nav">
        {arrNav.map((item, index) => (
          <div key={index}>
            {item.link
              && (
                <a
                  href={item.link}
                  target="_blank"
                  rel="noreferrer"
                  className="mobile_menu__nav_item"
                >
                  {item.label}
                </a>
              )}

            {!item.link
              && (
                <div
                  className="mobile_menu__nav_item"
                  onClick={item.click ? () => handleScroll(item.click) : () => { }}
                >
                  {item.label}
                </div>
              )}
          </div>
        ))}
        <div
          className="mobile_menu__send_request"
          onClick={openModalRequestHandler}
        >
          <img src={require('../../assets/double-files.svg').default} alt="Отправить заявку" />
          <div className="mobile_menu__send_request_text">Отправить заявку</div>
        </div>

        <a
          href="tel:8 800 200-01-80"
          className="mobile_menu__wrap_phone"
        >
          8 800 200-01-80
        </a>
      </div>
    </div>
  );
};

export default MobileMenu;