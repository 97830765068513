import React, { useState, useEffect } from 'react';
import './Representative.css';
import YandexMap from '../YandexMap/YandexMap';
import SelectSf from '../../components/UI/SelectSf/SelectSf';
import ContainerFormElement from '../../components/UI/ContainerFormElement/ContainerFormElement';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
// import { Context } from '../../globalState/context/context';

const Representative = ({
  offices = []
}) => {
  // const context = useContext(Context);
  const [nearestCityFinding, setNearestCityFinding] = useState(''); // найденный ближайший город относительно местонахождения пользователя

  const schema = yup.object({
    selectCity: yup.object(),
  }).required();

  const {
    watch, // аналог state как в useState()
    setValue, // аналог setState как в useState()
    control,
  } = useForm({
    defaultValues: schema.cast(),
    resolver: yupResolver(schema),
    mode: "onBlur"
  });

  const valueSelectCity = watch('selectCity'); // получаем сотояние селекта

  // устанавливаем значение по умолчанию для селекта
  useEffect(() => {
    if (offices) {
      // если твой город есть в списке городов, то подставится он, есл нет, то первый из списка
      const targetNearest = offices?.find((i) => i?.name === nearestCityFinding);
      setValue('selectCity', targetNearest || offices?.[0]);
    }
  }, [offices, setValue, nearestCityFinding]);

  return (
    <div className="representative">
      <div className="representative__wrap_map">
        <YandexMap
          isCoordinates={[
            valueSelectCity?.coordinates?.latitude,
            valueSelectCity?.coordinates?.longitude,
          ]}
          setNearestCityFinding={setNearestCityFinding}
          offices={offices}
        />
      </div>

      <div className="representative__wrap_all_text">
        <div className="representative__wrap_title">
          <h3 className="representative__title">
            Представительство
          </h3>

          <ContainerFormElement textLabel="">
            <SelectSf
              placeholder={"Выберите город"}
              className={"select_sf"}
              classNamePrefix={"select_sf"}
              options={offices}
              control={control}
              name={"selectCity"}
            >
            </SelectSf>
          </ContainerFormElement>
        </div>

        <div className="representative__wrap_cell_contacts">
          {valueSelectCity?.phone
            && (
              <a href={`tel: ${valueSelectCity?.phone}`} className="representative__text_contacts">
                {valueSelectCity?.phone}
              </a>
            )}

          {valueSelectCity?.fax
            && (
              <a href={`tel: ${valueSelectCity?.fax}`} className="representative__text_contacts">
                {valueSelectCity?.fax} {valueSelectCity?.fax && '(факс)'}
              </a>
            )}
          
          {valueSelectCity?.address
            && (
              <div className="representative__text_contacts mb mt">
                {valueSelectCity?.address}
              </div>
            )}
          {/* <a href="mailto: novosibirsk@deltaleasing.ru" className="representative__link_contacts">
            novosibirsk@deltaleasing.ru
          </a> */}
        </div>
      </div>
    </div>
  );
};

export default Representative;