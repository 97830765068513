export const initialState = {
  stateModel: {
    state: false,
    type: '',
  },
  stateStepsForm: {},
  stateDirectories: {},
  stateDirectoriesError: null,
  responsePostCalculate: {},
  responsePostCalculateError: null,
  responsePostError: null,

  stateLeasingForm: {},
  responseLeasingCalculate: {},
  responseLeasingCalculateError: null,

  responsePromotions: null,
  responsePromotionsError: null,

  stateMobileMenu: false,
  stateHeaderBanner: true,
}

export const reducer = (state, action) => {
  switch (action.type) {
    case 'MODAL':
      return {
        ...state,
        stateModel: {
          state: action.data.state,
          type: action.data.type
        }
      }

    case 'STATE_STEPS_FORM':
      return {
        ...state,
        stateStepsForm: action.data,
      }

    // содержит данные get запроса
    case 'GET_DIRECTORIES':
      return {
        ...state,
        stateDirectories: action.data,
      }
    case 'GET_DIRECTORIES_ERROR':
      return {
        ...state,
        stateDirectoriesError: action.data,
      }

    // содержит данные post запроса
    case 'RESPONSE_POST_CALCULATE':
      return {
        ...state,
        responsePostCalculate: action.data,
      }
    case 'RESPONSE_POST_CALCULATE_ERROR':
      return {
        ...state,
        responsePostCalculateError: action.data,
      }

    case 'RESPONSE_POST_ERROR':
      return {
        ...state,
        responsePostError: action.data,
      }



    case 'STATE_LEASING_FORM':
      return {
        ...state,
        stateLeasingForm: action.data,
      }

    // содержит данные post запроса
    case 'RESPONSE_LEASING_CALCULATE':
      return {
        ...state,
        responseLeasingCalculate: action.data,
      }
    case 'RESPONSE_LEASING_CALCULATE_ERROR':
      return {
        ...state,
        responseLeasingCalculateError: action.data,
      }

    case 'GET_PROMOTIONS':
      return {
        ...state,
        responsePromotions: action.data,
      }
    case 'GET_PROMOTIONS_ERROR':
      return {
        ...state,
        responsePromotionsError: action.data,
      }

    // мобильное меню
    case 'MOBILE_MENU':
      return {
        ...state,
        stateMobileMenu: action.data,
      }

    // банер под шапкой
    case 'HEADER_BANNER':
      return {
        ...state,
        stateHeaderBanner: action.data,
      }



    default:
      return state
  }
}