/* eslint-disable no-useless-escape */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useCallback, useContext, useEffect } from 'react';
import './StepsForm.css';
import { fetchHelper } from '../../helpers/api-helper';
import Cookies from 'js-cookie';
import SelectSf from '../UI/SelectSf/SelectSf';
import ContainerFormElement from '../UI/ContainerFormElement/ContainerFormElement';
import InputRange from '../UI/InputRange/InputRange';
import ButtonSf from '../UI/ButtonSf/ButtonSf';
import GroupRadioButton from '../UI/GroupRadioButton/GroupRadioButton';
import CheckboxSf from '../UI/CheckboxSf/CheckboxSf';
import InputSf from '../UI/InputSf/InputSf';
import CustomInputMask from '../UI/CustomInputMask/CustomInputMask';
import Tooltip from '../UI/Tooltip/Tooltip';
import { formatNumber } from '../../helpers/string-utils';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { Context } from '../../globalState/context/context';
import { arrAdvantage } from '../../helpers/common-helper';



const buttonsData = [
  { name: 'Название организации', id: 'a' },
  { name: 'ИНН', id: 'b' },
];

// const arrCircles = ['01', '02', '03'];

const StepsForm = () => {
  const context = useContext(Context);
  const yandexClientId = Cookies.get('_ym_uid');

  // данные get запроса calculate
  const {
    assetTypes,
    regions,
    companyActivityPeriods
  } = context?.state?.stateDirectories;

  const [step, setStep] = useState(0); // отвечает за шаги формы
  const [showCharts, setShowCharts] = useState(false); // раскрывает табличку на третьем шаге
  const [element, setElement] = useState(12); // начальное количество элементов в таблице
  const [blockSecondStep, setBlockSecondStep] = useState(false); // блокирует кнопку второго шага
  const [blockSecondElementForm, setBlockSecondElementForm] = useState(false); // блокирует элементы второй формы
  const [showSpinner, setShowSpinner] = useState(false); // включает/выключает спиннер
  const [blockSecondElementForm3, setBlockSecondElementForm3] = useState(false); // блокирует элементы формы редактирования первого шага
  const [showSpinner3, setShowSpinner3] = useState(false); // включает/выключает спиннер для редактирования первого шага
  const [blockEditFirstStep, setBlockEditFirstStep] = useState(false); // блокирует кнопку редактирования первого шага
  const [blockFinalStep, setBlockFinalStep] = useState(false); // блокирует кнопку ОТПРАВИТЬ ЗАЯВКУ на третьем шаге
  const [successFirstRequest, setSuccessFirstRequest] = useState(false); // успешный первый запрос
  const [minMaxStep, setMinMaxStep] = useState({
    min: 0,
    max: 0,
    step: 0,
  });
  const [minMaxStep3, setMinMaxStep3] = useState({
    min: 0,
    max: 0,
    step: 0,
  });
  const [changeBlock, setChangeBlock] = useState(false); // скрывает/показывает блок для редактирования формы на третьем шаге
  const responseCalculate = context?.state?.responsePostCalculate; // содержит ответ метода calculate
  const arrayAllPayments = responseCalculate?.payments?.map((i) => i?.paymentAmount); // массив всех платежей
  const summAllPayments = arrayAllPayments?.reduce((a, b) => a + b); // сумма всех платежей
  const letgthElement = responseCalculate?.payments?.length; // расчет количества для элементов в таблице

  const [toFirstStep, setToFirstStep] = useState(false); // возвращаемся со второго шага на первый

  // для подгрузки элементов таблицы на третьем шаге
  const showMore = useCallback(() => {
    setElement((prev) => prev + letgthElement);
  }, [setElement, letgthElement]);

  // ДЛЯ ФОРМЫ ПЕРВОГО
  const schema = yup.object().shape({
    selectCategory: yup.object()
      .required('Выберите один из вариантов'),
    inputContract: yup.number()
      .typeError('Недостаточно цифр')
      .required('Это поле необходимо заполнить')
      .default(60),
    rangeContract: yup.number()
      .typeError('Недостаточно цифр')
      .required('Это поле необходимо заполнить')
      .default(60),
    inputPrice: yup.number()
      .typeError('Недостаточно цифр')
      .required('Это поле необходимо заполнить')
      .default(10000000),
    rangePrice: yup.number()
      .typeError('Недостаточно цифр')
      .required('Это поле необходимо заполнить')
      .default(10000000),
    inputPrepayment: yup.number()
      .typeError('Недостаточно цифр')
      .required('Это поле необходимо заполнить')
      .default(0),
    rangePrepayment: yup.number()
      .typeError('Недостаточно цифр')
      .required('Это поле необходимо заполнить')
      .default(0),
  }); 

  const {
    watch, // аналог state как в useState()
    setValue, // аналог setState как в useState()
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: schema.cast(),
    resolver: yupResolver(schema),
    // defaultValues,
    mode: "onBlur"
  });

  // позволяет отслеживать значение элементов в реальном времени (аналог state в useState())
  const valueSelectCategory = watch('selectCategory');
  const valueInputContract = watch('inputContract');
  const valueRangeContract = watch('rangeContract');
  const valueInputPrice = watch('inputPrice');
  const valueRangePrice = watch('rangePrice');
  const valueInputPrepayment = watch('inputPrepayment');
  const valueRangePrepayment = watch('rangePrepayment');

  // сохраняем в стор результат первого шага
  const saveFirstStep = (data) => {
    context.dispatch({ type: 'STATE_STEPS_FORM', data, }); // сохраняем в стор результат первого шага

    setTimeout(() => {
      setStep(1);
    }, 100);
  };

  // отправка формы первого шага
  const submitForm = async (data) => {
    try {
      const response = await fetchHelper(`/landing/v1/schedule/calculate`, {
        method: 'POST',
        body: JSON.stringify({
          assetTypeId: data?.selectCategory?.id,
          paymentsQuantity: data?.rangeContract,
          currencyId: 1,
          assetCost: data?.rangePrice,
          advanceAmount: data?.rangePrepayment,
        }),
      });
      const dataRes = await response.json();

      if (response?.status === 200) {
        context.dispatch({ type: 'RESPONSE_POST_CALCULATE', data: dataRes }); // сохраняем в стор ответ POST запроса
        setSuccessFirstRequest(true); // разрешаем отправку второй формы
        setShowSpinner(true); // включаем спиннер
      }

      if (response?.status === 400 || response?.status === 404) {
        context.dispatch({ type: 'RESPONSE_POST_CALCULATE_ERROR', data: dataRes });
        setShowSpinner(false); // отключает спиннер
      }

      if (response?.status === 422) {
        context.dispatch({ type: 'RESPONSE_POST_CALCULATE_ERROR', data: {message: dataRes} });
        setShowSpinner(false); // отключает спиннер
      }

      if (response?.status === 500) {
        context.dispatch({ type: 'RESPONSE_POST_CALCULATE_ERROR', data: dataRes });
        setShowSpinner(false); // отключает спиннер
      }

    } catch (err) {
      setShowSpinner(false); // отключает спиннер
      console.log('ошибка в POST запросе calculate');
      context.dispatch({ type: 'RESPONSE_POST_CALCULATE_ERROR', data: { message: 'К сожалению, произошла ошибка, пожалуйста, повторите попытку позже.' } });
    }
  };

  // устанавливаем значения в поля первого шага
  useEffect(() => {
    if (assetTypes) {
      setValue('selectCategory', assetTypes?.[0]);
    }
  }, [assetTypes]);

  // если в селекте "Предмет лизинга" выбрана опция "Оборудование", то Первоначальный
  // взнос устанавливается в 5%, все остальные 0%
  useEffect(() => {
    if (valueSelectCategory?.id === 1) {
      setValue('inputPrepayment', parseInt(valueInputPrice / 100 * 5));
      setValue('rangePrepayment', parseInt(valueInputPrice / 100 * 5));
    }
    if (valueSelectCategory?.id !== 1) {
      setValue('inputPrepayment', 0);
      setValue('rangePrepayment', 0);
    }
  }, [valueSelectCategory]);


  // устанавливаем значения в поля первого шага после того как на втором шаге нажали на кнопку НАЗАД
  // поля и так сохраняют значения, но цифры слипаются по этому используется всего один элемент формы для перерендера формы, это своеобразная обманка
  useEffect(() => {
    if (toFirstStep) {
      setValue('inputContract', context.state.stateStepsForm.inputContract);
      setValue('rangeContract', context.state.stateStepsForm.inputContract);
    }
  }, [toFirstStep]);

  // устанавливаем значения по умолчанию в инпут-рендже СТОИМОСТЬ
  useEffect(() => {
    setMinMaxStep({
      min: 1000000,
      max: 200000000,
      step: 50000,
    })
  }, [setMinMaxStep]);
  // КОНЕЦ ДЛЯ ПЕРВОГОЙ ФОРМЫ
  
  // ДЛЯ ФОРМЫ ВТОРОГО ШАГА
  const schema2 = yup.object().shape({
    radioFirst2: yup.string()
      .typeError('Выберите один из вариантов')
      .required()
      .default(buttonsData[0].id), // позволяет установить дефолтное значение, работает в связке с defaultValues: schema.cast(),
    organizationOrINN2: yup.string()
      .required('Это поле необходимо заполнить')
      .trim()
      .when('radioFirst2', {
        is: 'b', // если выбрано радиокнопка ИНН, то
        then: (rule) => {
          clearErrors2('organizationOrINN2'); 
          return rule.nullable().notRequired();
        }, // поле organizationOrINN2 становится не обязательным
      }),
    inputINN2: yup.string()
      .required('Это поле необходимо заполнить')
      .max(12, 'Максимум 12 символов')
      .matches(/^[0-9]*$/gmi, "Должно быть числом") // разрешены пустая строка и только цифры
      .test("0 or min 10", "Минимум 10 символов", (value) => {
        if (value?.length > 0 && value?.length < 10) return false // разрешена пустая строка и минимум 10 символов
        else return true
      })
      .when('radioFirst2', {
        is: 'a', // если выбрано радиокнопка (Название организации), то
        then: (rule) => {
          return rule.nullable().notRequired();
        }, // поле inputINN2 становится не обязательным
      }),
    agree2: yup.bool()
      .oneOf([true], 'Примите согласие'),
    selectTermWork2: yup.object()
      .required('Выберите один из вариантов'),
    selectLocation2: yup.object()
      .required('Выберите один из вариантов'),
    inputUser2: yup.string()
      .required('Это поле необходимо заполнить')
      .trim()
      .matches(/^[-а-яА-ЯёЁ\s]+$/, "Разрешены только кириллица и дефис"),
    inputPhone2: yup.string()
      .required('Это поле необходимо заполнить')
      .matches(/^((8|\+7)[\- ]?)?(\(?\d{3}\)?[\- ]?)?[\d\- ]{7,10}$/gmi, "Введите корректный номер телефона"),
    inputEmail2: yup.string()
      .required('Это поле необходимо заполнить')
      .email('Пожалуйста, введите корректный адрес электронной почты'),
  }, [['radioFirst2', 'organizationOrINN2']]); // указываются имена полей для возможности циклической обработки (типо зависимостей)

  const {
    watch: watch2, // аналог state как в useState()
    setValue: setValue2, // аналог setState как в useState()
    control: control2,
    register: register2,
    handleSubmit: handleSubmit2,
    clearErrors: clearErrors2,
    formState: { errors: errors2, isValid: isValid2},
  } = useForm({
    defaultValues: schema2.cast(),
    resolver: yupResolver(schema2),
    // defaultValues,
    // mode: "onBlur"
    mode: "onChange"
  });

  // позволяет отслеживать значение элементов в реальном времени (аналог state в useState())
  const radioFirstWatch2 = watch2('radioFirst2');
  const agreeWatch2 = watch2('agree2');
  const agreeSelectTermWork2 = watch2('selectTermWork2');

  // сохраняем в стор результат второго шага
  const saveSecondStep = async (data) => {
    context.dispatch({
      type: 'STATE_STEPS_FORM',
      data: {
        ...context?.state?.stateStepsForm,
        radioFirst: data?.radioFirst2,
        organizationOrINN: data?.organizationOrINN2,
        inputINN: data?.inputINN2,
        agree: data?.agree2,
        selectTermWork: data?.selectTermWork2,
        selectLocation: data?.selectLocation2,
        inputUser: data?.inputUser2,
        inputPhone: data?.inputPhone2,
        inputEmail: data?.inputEmail2,
      },
    });
  };

  // отправка формы второго шага
  const submitForm2 = async (data) => {
    try {
      const response = await fetchHelper(`/landing/v1/schedule/post`, {
        method: 'POST',
        body: JSON.stringify({
          calculationUID: responseCalculate?.uid,
          assetName: data?.selectCategory3?.name || context?.state?.stateStepsForm?.selectCategory?.name,
          typeId: 1,
          customer: {
            companyName: context?.state?.stateStepsForm?.organizationOrINN,
            companyTIN: context?.state?.stateStepsForm?.inputINN,
            companyActivityPeriodId: context?.state?.stateStepsForm?.selectTermWork?.id,
            regionId: context?.state?.stateStepsForm?.selectLocation?.id,
            person: context?.state?.stateStepsForm?.inputUser,
            phone: context?.state?.stateStepsForm?.inputPhone,
            email: context?.state?.stateStepsForm?.inputEmail,
          },
          comment: context?.state?.stateStepsForm?.textareaYourQuestion,
          clientId: yandexClientId
        }),
      });
      const dataRes = await response.json();

      if (response?.status === 200) {
        setBlockSecondStep(false); //разблокируем кнопку второго шага
        setTimeout(() => {
          setStep(2);
          setSuccessFirstRequest(false); // блокируем срабатывание useEffect который отправляет вторую форму
        }, 100);
      }

      if (response?.status === 400 || response?.status === 404) {
        context.dispatch({ type: 'RESPONSE_POST_ERROR', data: dataRes });
      }

      if (response?.status === 422) {
        context.dispatch({ type: 'RESPONSE_POST_ERROR', data: {message: dataRes} });
      }

      if (response?.status === 500) {
        context.dispatch({ type: 'RESPONSE_POST_ERROR', data: dataRes });
      }
    } catch (err) {
      console.log('ошибка в POST запросе post');
      context.dispatch({ type: 'RESPONSE_POST_ERROR', data: { message: 'К сожалению, произошла ошибка, пожалуйста, повторите попытку позже.' } });
    } finally {
      setShowSpinner(false); // отключает спиннер
      setTimeout(() => {
        setBlockSecondElementForm(false); // разблокирует элементы второй формы
      }, 5000);
    }
  };

  // блокируем кнопку второго шага если чекбокс выключен
  useEffect(() => {
    if (agreeWatch2 !== undefined && !agreeWatch2) {
      setBlockSecondStep(true); // блокируем кнопку второго шага
    } else setBlockSecondStep(false); // разблокируем кнопку второго шага
  }, [agreeWatch2]);

  // устанавливаем значения в поля второго шага
  useEffect(() => {
    if (assetTypes) {
      setValue2('selectTermWork2', companyActivityPeriods?.[companyActivityPeriods?.length - 1]);
    }
  }, [assetTypes]);

  // отправляем результат второго шага после отправки первого шага
  useEffect(() => {
    if (successFirstRequest) {
      handleSubmit2((data) => submitForm2(data))();
    }
  }, [successFirstRequest]);
  // КОНЕЦ ДЛЯ ФОРМЫ  ВТОРОГО ШАГА


  // ДЛЯ ФОРМЫ ТРЕТЬЕГО ШАГА
  const schema3 = yup.object().shape({
    selectCategory3: yup.object()
      .required('Выберите один из вариантов'),
    inputContract3: yup.number()
      .typeError('Недостаточно цифр')
      .required('Это поле необходимо заполнить')
      .default(60),
    rangeContract3: yup.number()
      .typeError('Недостаточно цифр')
      .required('Это поле необходимо заполнить')
      .default(60),
    inputPrice3: yup.number()
      .typeError('Недостаточно цифр')
      .required('Это поле необходимо заполнить')
      .default(0),
    rangePrice3: yup.number()
      .typeError('Недостаточно цифр')
      .required('Это поле необходимо заполнить')
      .default(0),
    inputPrepayment3: yup.number()
      .typeError('Недостаточно цифр')
      .required('Это поле необходимо заполнить')
      .default(49),
    rangePrepayment3: yup.number()
      .typeError('Недостаточно цифр')
      .required('Это поле необходимо заполнить')
      .default(49),
  });

  const {
    watch: watch3, // аналог state как в useState()
    setValue: setValue3, // аналог setState как в useState()
    control: control3,
    register: register3,
    handleSubmit: handleSubmit3,
    formState: { errors: errors3 },
  } = useForm({
    defaultValues: schema3.cast(),
    resolver: yupResolver(schema3),
    // defaultValues,
    mode: "onBlur"
  });

  // позволяет отслеживать значение элементов в реальном времени (аналог state в useState())
  const valueInputContract3 = watch3('inputContract3');
  const valueRangeContract3 = watch3('rangeContract3');
  const valueInputPrice3 = watch3('inputPrice3');
  const valueRangePrice3 = watch3('rangePrice3');
  const valueInputPrepayment3 = watch3('inputPrepayment3');
  const valueRangePrepayment3 = watch3('rangePrepayment3');

  // сохраняем в стор результат второго шага
  const editFirstStep = async (data) => {
    context.dispatch({ 
      type: 'STATE_STEPS_FORM',
      data: {
        ...context?.state?.stateStepsForm,
        inputContract: data?.inputContract3,
        rangeContract: data?.rangeContract3,
        inputPrepayment: data?.inputPrepayment3,
        rangePrepayment: data?.rangePrepayment3,
        inputPrice: data?.inputPrice3,
        rangePrice: data?.rangePrice3,
        selectCategory: data?.selectCategory3,
      }, 
    });
  };

  // отправка формы редактирования первого шага
  const submitForm3 = async (data) => {
    try {
      const response = await fetchHelper(`/landing/v1/schedule/calculate`, {
        method: 'POST',
        body: JSON.stringify({
          assetTypeId: data?.selectCategory3?.id,
          paymentsQuantity: data?.rangeContract3,
          currencyId: 1,
          assetCost: data?.rangePrice3,
          advanceAmount: data?.rangePrepayment3,
        }),
      });

      const dataRes = await response.json();

      if (response?.status === 200) {
        context.dispatch({ type: 'RESPONSE_POST_CALCULATE', data: dataRes }); // сохраняем в стор ответ POST запроса
        setTimeout(() => {
          setBlockEditFirstStep(false); // разблокирование кнопки ПЕРЕСЧИТАТЬ
          setChangeBlock(false); // сменяем форму
          setBlockSecondElementForm3(false); // разблокирует элементы формы редактирования первого шага
          context.dispatch({ type: 'RESPONSE_POST_CALCULATE_ERROR', data: {} });
          submitForm2(data)
        }, 1000);
      }

      if (response?.status === 400 || response?.status === 404) {
        setShowSpinner3(false); // отключает спиннер
        context.dispatch({ type: 'RESPONSE_POST_CALCULATE_ERROR', data: dataRes });
      }

      if (response?.status === 422) {
        setShowSpinner3(false); // отключает спиннер
        context.dispatch({ type: 'RESPONSE_POST_CALCULATE_ERROR', data: {message: dataRes} });
      }
    
      if (response?.status === 500) {
        setShowSpinner3(false); // отключает спиннер
        context.dispatch({ type: 'RESPONSE_POST_CALCULATE_ERROR', data: dataRes });
      }

    } catch (err) {
      console.log('ошибка в POST запросе calculate редактирования первого шага');
      context.dispatch({ type: 'RESPONSE_POST_CALCULATE_ERROR', data: { message: 'К сожалению, произошла ошибка, пожалуйста, повторите попытку позже.' } });
    } finally {
      setShowSpinner3(false); // выключаем спинер
      setTimeout(() => {
        setBlockSecondElementForm3(false); // разблокирует элементы формы редактирования первого шага
        setBlockEditFirstStep(false); // разблокирование кнопки ПЕРЕСЧИТАТЬ
      }, 5000);
    }
  };

  // устанавливаем значения по умолчанию в инпут-рендже СТОИМОСТЬ
  useEffect(() => {
    setMinMaxStep3({
      min: 1000000,
      max: 200000000,
      step: 50000,
    })
  }, [setMinMaxStep3]);
  
  // устанавливаем значения в поля
  useEffect(() => {
    // на третьем шаге нажимая ИЗМЕНИТЬ УСЛОВИЯ
    if (changeBlock) { 
      setValue3('inputContract3', context?.state?.stateStepsForm?.rangeContract);
      setValue3('rangeContract3', context?.state?.stateStepsForm?.rangeContract);
      setValue3('inputPrepayment3', context?.state?.stateStepsForm?.inputPrepayment);
      setValue3('rangePrepayment3', context?.state?.stateStepsForm?.rangePrepayment);
      setValue3('inputPrice3', context?.state?.stateStepsForm?.inputPrice);
      setValue3('rangePrice3', context?.state?.stateStepsForm?.rangePrice);
      setValue3('selectCategory3', context?.state?.stateStepsForm?.selectCategory);
    };
  }, [setValue3, context?.state?.stateStepsForm, changeBlock]);
  // КОНЕЦ ДЛЯ ФОРМЫ ТРЕТЬЕГО ШАГА


  const clearForms = () => {
    setValue('selectCategory', assetTypes?.[0]);
    setValue('inputContract', 60);
    setValue('rangeContract', 60);
    setValue('inputPrice', 1000000);
    setValue('rangePrice', 1000000);
    setValue('inputPrepayment', 0);
    setValue('rangePrepayment', 0);

    setValue2('selectTermWork2', companyActivityPeriods?.[companyActivityPeriods?.length - 1]);
    setValue2('organizationOrINN2', '');
    setValue2('inputINN2', '');
    setValue2('agree2', true);
    setValue2('selectLocation2', {});
    setValue2('inputUser2', '');
    setValue2('inputPhone2', '');
    setValue2('inputEmail2', '');
  };

  // финальная отправка формы на кнопку ОТПРАВИТЬ ЗАЯВКУ
  const finalStep = async (data) => {
    setBlockFinalStep(true); // блокируем кнопку ОТПРАВИТЬ ЗАЯВКУ

    setTimeout(() => {
      context.dispatch({ type: 'MODAL', data: { state: true, type: 'success' }, });
      setBlockFinalStep(false); // разблокируем кнопку ОТПРАВИТЬ ЗАЯВКУ
      setStep(0); // перводим на первый шаг
      context.dispatch({ type: 'STATE_STEPS_FORM', data: {}}); // очищаем стор форм
      clearForms();
    }, 1000);

    // try {
    //   await fetchHelper(`/landing/v1/schedule/post`, {
    //     method: 'POST',
    //     body: JSON.stringify({
    //       calculationUID: responseCalculate?.uid,
    //       assetName: context?.state?.stateStepsForm?.selectCategory?.name,
    //       typeId: 2,
    //       customer: {
    //         companyName: context?.state?.stateStepsForm?.organizationOrINN,
    //         companyTIN: context?.state?.stateStepsForm?.inputINN,
    //         companyActivityPeriodId: context?.state?.stateStepsForm?.selectTermWork?.id,
    //         regionId: context?.state?.stateStepsForm?.selectLocation?.id,
    //         person: context?.state?.stateStepsForm?.inputUser,
    //         phone: context?.state?.stateStepsForm?.inputPhone,
    //         email: context?.state?.stateStepsForm?.inputEmail,
    //       },
    //       comment: context?.state?.stateStepsForm?.textareaYourQuestion,
    //       clientId: yandexClientId,
    //     }),
    //   });
    //
    //   setTimeout(() => {
    //     context.dispatch({ type: 'MODAL', data: { state: true, type: 'success' }, });
    //     setBlockFinalStep(false); // разблокируем кнопку ОТПРАВИТЬ ЗАЯВКУ
    //     setStep(0); // перводим на первый шаг
    //     context.dispatch({ type: 'STATE_STEPS_FORM', data: {}}); // очищаем стор форм
    //     clearForms();
    //   }, 1000);
    // } catch (err) {
    //   console.log('ошибка в POST запросе post финальной формы');
    //   context.dispatch({ type: 'RESPONSE_POST_ERROR', data: err });
    // }
  };
  

  // содержимое заголовка на определенном шаге
  const stepTitle = (step) => {
    if (step === 0) return 'Рассчитать ежемесячный лизинговый платеж и&nbsp;экономию';
    if (step === 1) return 'Заполнение формы <br /> не обязывает вас оформить лизинг';
    if (step === 2) return 'Предварительный расчет <br /> по вашим условиям';
  };

  // содержимое подзаголовка на определенном шаге
  const stepDescription = (step) => {
    if (step === 0) return (
      <div className="steps_form__wrap_advantage">
        {arrAdvantage.map((i, index) =>
          <div className="steps_form__wrap_advantage_item" key={index}>
            <div className="steps_form__wrap_advantage_icon">
              <img src={`${require('../../assets/green_gal.svg').default}`} alt="Галка" />
            </div>
            <div className="steps_form__wrap_advantage_icon_text">
              {i?.title}
            </div>
          </div>
        )}
      </div>
    )
    if (step === 1) return 'Гарантируем, что ваши данные останутся в&nbsp;безопасности'
    if (step === 2) return (
      <div className="steps_form__wrap_description">
        {!changeBlock 
          && (
            <div className="steps_form__wrap_result_first_step">
              <div className="steps_form__cell_result_first_step">
                <div className="steps_form__cell_result_first_step_mini_text">
                  Предмет лизинга
                </div>
                <div className="steps_form__cell_result_first_step_middle_text">
                  {context?.state?.stateStepsForm?.selectCategory?.name}
                </div>
              </div>

              <div className="steps_form__cell_result_first_step">
                <div className="steps_form__cell_result_first_step_mini_text">
                  Срок договора, мес.
                </div>
                <div className="steps_form__cell_result_first_step_middle_text">
                  {context?.state?.stateStepsForm?.rangeContract}
                </div>
              </div>

              <div className="steps_form__cell_result_first_step">
                <div className="steps_form__cell_result_first_step_mini_text">
                  Стоимость, руб.
                </div>
                <div className="steps_form__cell_result_first_step_middle_text">
                  {formatNumber(context?.state?.stateStepsForm?.rangePrice)}
                </div>
              </div>

              <div className="steps_form__cell_result_first_step">
                <div className="steps_form__cell_result_first_step_mini_text">
                  Первоначальный взнос, руб.
                </div>
                <div className="steps_form__cell_result_first_step_middle_text">
                  {formatNumber(parseInt(context?.state?.stateStepsForm?.rangePrepayment))}
                </div>
              </div>

              <div className="steps_form__wrap_btn_edit_first_step">
                <ButtonSf
                  typeBtn='fifth'
                  onClick={() => {
                    setChangeBlock(true);
                  }}
                >
                  Изменить условия
                </ButtonSf>
              </div>
            </div>
          )}

        {changeBlock
          && (
            <div className="">
              <div className="form3">
                <ContainerFormElement textLabel="Предмет лизинга">
                  <SelectSf
                    placeholder={"Легковой автомобиль"}
                    className={"select_sf"}
                    classNamePrefix={"select_sf"}
                    error={errors3.selectCategory3}
                    options={assetTypes}
                    control={control3}
                    name={"selectCategory3"}
                    disabled={blockSecondElementForm3}
                  >
                  </SelectSf>
                  {errors3.selectCategory3 && <p className="steps_form__line__text_error">{errors3.selectCategory3.message}</p>}
                </ContainerFormElement>

                <ContainerFormElement textLabel="Срок договора, мес.">
                  <div className="steps_form__right_plaseholder">
                    <InputRange
                      min={6}
                      max={60}
                      maxlength={2}
                      step={6}
                      error={(errors3.inputContract3 && errors3.rangeContract3)}
                      control={control3}
                      valueInput={valueInputContract3}
                      valueRange={valueRangeContract3}
                      setValue={setValue3}
                      disabled={blockSecondElementForm3}
                      inputValidation={{
                        ...register3("inputContract3", {
                          valueAsNumber: true,
                        })
                      }}
                      rangeValidation={{
                        ...register3("rangeContract3",)
                      }}
                    />
                    <div className="steps_form__right_plaseholder_text">до 60 месяцев</div>
                  </div>
                  {(errors3.inputContract && errors3.rangeContract) && <p className="steps_form__line__text_error">Минимальное число 7</p>}
                </ContainerFormElement>

                <div className="steps_form__line">
                  <div className="steps_form__line_left_part">
                    <ContainerFormElement 
                      textLabel="Стоимость, руб."
                    >
                      <InputRange
                        min={minMaxStep3.min}
                        max={minMaxStep3.max}
                        step={minMaxStep3.step}
                        error={(errors3.inputPrice3 && errors3.rangePrice3)}
                        control={control3}
                        valueInput={valueInputPrice3}
                        valueRange={valueRangePrice3}
                        setValue={setValue3}
                        disabled={blockSecondElementForm3}
                        inputValidation={{
                          ...register3("inputPrice3", {
                            valueAsNumber: true,
                          })
                        }}
                        rangeValidation={{
                          ...register3("rangePrice3",)
                        }}
                      />
                      {(errors3.inputPrice3 && errors3.rangePrice3) && <p className="steps_form__line__text_error">Минимальное число 7</p>}
                    </ContainerFormElement>
                  </div>
                </div>

                <div className="steps_form__line">
                  <div className="steps_form__line_left_part">
                    <ContainerFormElement
                      textLabel="Первоначальный взнос, руб."
                    >
                      <InputRange
                        min={0}
                        max={parseInt(valueInputPrice3 / 100 * 49)}
                        step={parseInt(valueInputPrice3 / 100 * 1)}
                        error={(errors3.inputPrepayment3 && errors3.rangePrepayment3)}
                        control={control3}
                        valueInput={valueInputPrepayment3}
                        valueRange={valueRangePrepayment3}
                        setValue={setValue3}
                        disabled={blockSecondElementForm3}
                        inputValidation={{
                          ...register3("inputPrepayment3", {
                            valueAsNumber: true,
                          })
                        }}
                        rangeValidation={{
                          ...register3("rangePrepayment3",)
                        }}
                      />
                      {(errors3.inputPrepayment3 && errors3.rangePrepayment3) && <p className="steps_form__line__text_error">Минимальное число 7</p>}
                    </ContainerFormElement>
                  </div>

                  <div className="steps_form__line_right_part line_mode_small">
                    <div className="steps_form__line_big_text">{Math.round(valueRangePrepayment3 * 100 / valueInputPrice3)}%</div>
                  </div>
                </div>

                {context?.state?.responsePostCalculateError?.message
                  && (
                    <div className="steps_form__response_error">
                      {context?.state?.responsePostCalculateError?.message}
                    </div>
                  )}

                <div className="steps_form__wrap_btn_and_spinner">
                  <div className="steps_form__wrap_btn">
                    <ButtonSf
                      typeBtn='fifth'
                      type="submit"
                      disabled={blockEditFirstStep}
                      onClick={() => {
                        setShowSpinner3(true); // включаем спинер
                        setBlockSecondElementForm3(true); // блокирует элементы формы редактирования первого шага
                        setBlockEditFirstStep(true); // блокируем кнопку
                        handleSubmit3((data) => editFirstStep(data))(); // редактирование и сохранение в стор данных из полей первого шага
                        handleSubmit3((data) => submitForm3(data))(); // отправка формы первого шага
                      }}
                    >
                      пересчитать
                    </ButtonSf>
                  </div>

                  {showSpinner3
                    && (
                      <div className="steps_form__wrap_spinner_step3">
                        <img src={require('../../assets/spinner.svg').default} alt="Раскрыть" />
                      </div>
                    )}
                </div>
              </div>
              
            </div>
          )}
      </div>
    )
  };

  return (
    <div className="steps_form" id="calculator">
      <div className="steps_form__part_text">
        {/* ВРЕМЕННО скрыть */}
        {/* <div className="steps_form__wrap_circles_steps">
          {arrCircles.map((item, index) => 
            <div className={`steps_form__circles_step ${index === step ? 'active' : ''} ${index < step ? 'after-active' : ''}`} key={index}>
              {item}
            </div>
          )}
        </div> */}

        <div className="steps_form__wrap_title_and_description">
          <h2
            className="steps_form__title"
            dangerouslySetInnerHTML={{ __html: stepTitle(step) }}
          />

          {step !== 1
            && (
              <div className="steps_form__description">
                {stepDescription(step)}
              </div>
            )}
          
          {step === 1
            && (
              <div
                className="steps_form__description"
                dangerouslySetInnerHTML={{ __html: stepDescription(step) }}
              />
            )}
          
        </div>
      </div>

      <div className="steps_form__part_form">
        <div className="form1" id="form1">
          {step === 0 &&
            <>
              <ContainerFormElement textLabel="Предмет лизинга">
                <SelectSf
                  placeholder={"Оборудование"}
                  className={"select_sf"}
                  classNamePrefix={"select_sf"}
                  error={errors.selectCategory}
                  options={assetTypes}
                  control={control}
                  name={"selectCategory"}
                >
                </SelectSf>
                {errors.selectCategory && <p className="steps_form__line__text_error">{errors.selectCategory.message}</p>}
              </ContainerFormElement>

              <ContainerFormElement textLabel="Срок договора, мес.">
                <div className="steps_form__right_plaseholder">
                  <InputRange
                    min={6}
                    max={60}
                    maxlength={2}
                    step={6}
                    error={(errors.inputContract && errors.rangeContract)}
                    control={control}
                    valueInput={valueInputContract}
                    valueRange={valueRangeContract}
                    setValue={setValue}
                    inputValidation={{
                      ...register("inputContract", {
                        valueAsNumber: true,
                      })
                    }}
                    rangeValidation={{
                      ...register("rangeContract",)
                    }}
                  />
                  <div className="steps_form__right_plaseholder_text">до 60 месяцев</div>
                </div>
                {(errors.inputContract && errors.rangeContract) && <p className="steps_form__line__text_error">Минимальное число 7</p>}
              </ContainerFormElement>

              <div className="steps_form__line">
                <div className="steps_form__line_left_part">
                  <ContainerFormElement
                    textLabel="Стоимость, руб."
                  >
                    <InputRange
                      min={minMaxStep.min}
                      max={minMaxStep.max}
                      step={minMaxStep.step}
                      error={(errors.inputPrice && errors.rangePrice)}
                      control={control}
                      valueInput={valueInputPrice}
                      valueRange={valueRangePrice}
                      setValue={setValue}
                      inputValidation={{
                        ...register("inputPrice", {
                          valueAsNumber: true,
                        })
                      }}
                      rangeValidation={{
                        ...register("rangePrice",)
                      }}
                    />
                    {(errors.inputPrice && errors.rangePrice) && <p className="steps_form__line__text_error">Минимальное число 7</p>}
                  </ContainerFormElement>
                </div>
              </div>

              <div className="steps_form__line">
                <div className="steps_form__line_left_part">
                  <ContainerFormElement
                    textLabel="Первоначальный взнос, руб."
                  >
                    <InputRange
                      min={0}
                      max={parseInt(valueInputPrice / 100 * 49)}
                      step={parseInt(valueInputPrice / 100 * 1)}
                      error={(errors.inputPrepayment && errors.rangePrepayment)}
                      control={control}
                      valueInput={valueInputPrepayment}
                      valueRange={valueRangePrepayment}
                      setValue={setValue}
                      inputValidation={{
                        ...register("inputPrepayment", {
                          valueAsNumber: true,
                        })
                      }}
                      rangeValidation={{
                        ...register("rangePrepayment",)
                      }}
                    />
                    {/* {+valueRangePrepayment === 0 && <p className="steps_form__line__text_error">Минимум один процент</p>} */}
                  </ContainerFormElement>
                </div>

                <div className="steps_form__line_right_part line_mode_small">
                <div className="steps_form__line_big_text">{Math.round(valueRangePrepayment * 100 / valueInputPrice)}%</div>
                </div>
              </div>

              {context?.state?.stateDirectoriesError?.message
                && (
                  <div className="steps_form__response_error">
                    {context?.state?.stateDirectoriesError?.message}
                  </div>
                )}
              
              <div className="steps_form__wrap_btn_first_step">
                <ButtonSf 
                  typeBtn='second'
                  type="submit"
                  onClick={() => {
                    handleSubmit((data) => saveFirstStep(data))(); // сохранение в стор данных из полей первого шага
                    setToFirstStep(false);
                  }}
                >
                  рассчитать лизинг
                </ButtonSf>
              </div>
            </>
          }
        </div>

        <div className="form2">
          {step === 1 &&
            <>
              <div className="steps_form__wrap_radiobuttons_step_2">
                <GroupRadioButton
                  data={buttonsData}
                  value={radioFirstWatch2}
                  disabled={blockSecondElementForm}
                  validation={{
                    ...register2("radioFirst2")
                  }}
                />
                <div className="steps_form__wrap_radiobuttons_icon">
                  <Tooltip
                    messageTooltip={`
                      данная информация собирается для ускорения рассмотрения заявки 
                      и подготовки индивидуального предложения.
                    `}
                    rightPosotion
                  >
                    <img src={require('../../assets/info.svg').default} alt="Информация" />
                  </Tooltip>
                </div>
              </div>

              <div className={`steps_form__wrap_input_org_or_inn ${radioFirstWatch2 === 'b' ? 'hidden' : ''}`}>
                <ContainerFormElement textLabel={'Название организации'}>
                  <InputSf
                    placeholder={'ООО «Компания»'}
                    error={errors2.organizationOrINN2}
                    disabled={blockSecondElementForm}
                    validation={{
                      ...register2("organizationOrINN2")
                    }}
                  />
                  {errors2.organizationOrINN2 && <p className="steps_form__line__text_error">{errors2.organizationOrINN2.message}</p>}
                </ContainerFormElement>
              </div>

              <div className={`steps_form__wrap_input_org_or_inn ${radioFirstWatch2 === 'a' ? 'hidden' : ''}`}>
                <ContainerFormElement textLabel={'ИНН'}>
                  <InputSf
                    placeholder={'Введите ИНН'}
                    error={errors2.inputINN2}
                    disabled={blockSecondElementForm}
                    maxlength={12}
                    onlyNum
                    setValue={setValue2}
                    validation={{
                      ...register2("inputINN2")
                    }}
                  />
                  {errors2.inputINN2 && <p className="steps_form__line__text_error">{errors2.inputINN2.message}</p>}
                </ContainerFormElement>
              </div>

              <div className="steps_form__line column">
                <div className="steps_form__line_left_part line_mode_small">
                  <ContainerFormElement
                    textLabel="Срок работы на рынке"
                    showIcon={agreeSelectTermWork2.id === 1}
                    messageTooltip="Возможно, вам потребуется поручительство"
                  >
                    <SelectSf
                      placeholder={"До 1 года"}
                      className={"select_sf"}
                      classNamePrefix={"select_sf"}
                      disabled={blockSecondElementForm}
                      options={companyActivityPeriods}
                      control={control2}
                      name={"selectTermWork2"}
                    >
                    </SelectSf>
                  </ContainerFormElement>
                </div>

                <div className="steps_form__line_right_part">
                  <ContainerFormElement
                    textLabel="Местоположение головного офиса"
                  >
                    <SelectSf
                      placeholder={"Местоположение"}
                      className={"select_sf"}
                      classNamePrefix={"select_sf"}
                      error={errors2.selectLocation2}
                      disabled={blockSecondElementForm}
                      isSearchable
                      options={regions}
                      control={control2}
                      name={"selectLocation2"}
                    >
                    </SelectSf>
                    {errors2.selectLocation2 && <p className="steps_form__line__text_error">{errors2.selectLocation2.message}</p>}
                  </ContainerFormElement>
                </div>
              </div>

              <div className="steps_form__line column">
                <div className="steps_form__line_left_part line_mode_small">
                  <ContainerFormElement textLabel="Телефон">
                    <CustomInputMask
                      control={control2}
                      error={errors2.inputPhone2}
                      disabled={blockSecondElementForm}
                      name={"inputPhone2"}
                    />
                    {errors2.inputPhone2 && <p className="steps_form__line__text_error">{errors2.inputPhone2.message}</p>}
                  </ContainerFormElement>
                </div>

                <div className="steps_form__line_right_part">
                  <ContainerFormElement textLabel="Контактное лицо">
                    <InputSf
                      placeholder="Иванов Сергей Сергеевич"
                      error={errors2.inputUser2}
                      disabled={blockSecondElementForm}
                      validation={{
                        ...register2("inputUser2")
                      }}
                    />
                    {errors2.inputUser2 && <p className="steps_form__line__text_error">{errors2.inputUser2.message}</p>}
                  </ContainerFormElement>
                </div>
              </div>

              <ContainerFormElement textLabel="Адрес эл. почты">
                <InputSf
                  placeholder="ivanov@mail.ru"
                  error={errors2.inputEmail2}
                  disabled={blockSecondElementForm}
                  validation={{
                    ...register2("inputEmail2")
                  }}
                />
                {errors2.inputEmail2 && <p className="steps_form__line__text_error">{errors2.inputEmail2.message}</p>}
              </ContainerFormElement>

              <div className="steps_form__line__wrap_checkbox">
                <CheckboxSf
                  label="Нажимая кнопку «Продолжить», я даю согласие на обработку моих персональных данных <br />ООО «ДельтаЛизинг» согласно <a href='https://site.deltaleasing.ru/confidentiality' class='personal_link' target='_blank'>Политике конфиденциальности</a>."
                  check={agreeWatch2}
                  disabled={blockSecondElementForm}
                  validation={{
                    ...register2("agree2")
                  }}
                />
                {errors2.agree2 && <p className="steps_form__line__text_error">{errors2.agree2.message}</p>}
              </div>

              {context?.state?.responsePostCalculateError?.message
                && (
                  <div className="steps_form__response_error">
                    {context?.state?.responsePostCalculateError?.message}
                  </div>
                )}
              {context?.state?.responsePostError?.message
                && (
                  <div className="steps_form__response_error">
                    {context?.state?.responsePostError?.message}
                  </div>
                )}

              <div className="steps_form__wrap_for_btns">
                <div className="steps_form__wrap_btn_back">
                  <ButtonSf
                    typeBtn='third'
                    iconLeft
                    iconName={require('../../assets/array_left_gray.svg').default}
                    onClick={() => {
                      setStep(0);
                      setToFirstStep(true);
                    }}
                  >
                    назад
                  </ButtonSf>
                </div>

                <div className="steps_form__wrap_for_spinner">
                  {showSpinner
                    && (
                      <div className="steps_form__wrap_spinner">
                        <img src={require('../../assets/spinner.svg').default} alt="Раскрыть" />
                      </div>
                    )}

                  <div className="steps_form__wrap_btn_second_step">
                    <ButtonSf
                      typeBtn='second'
                      type="submit"
                      disabled={blockSecondStep}
                      onClick={() => {
                        if (isValid2) {
                          setBlockSecondElementForm(true); // блокирует элементы второй формы
                          setShowSpinner(true); // включает спиннер
                          setBlockSecondStep(true); // блокирует кнопку второй формы
                        }
                        handleSubmit((data) => isValid2 ? submitForm(data) : () => {})(); // отправка формы первого шага
                        handleSubmit2((data) => saveSecondStep(data))(); // сохранение в стор данных из полей второго шага
                      }}
                    >
                      Продолжить
                    </ButtonSf>
                  </div>
                </div>
              </div>
              

              {/* {!!context?.state?.responsePostError
                && (
                  <div className="steps_form__btn_error">
                    К сожалению, сейчас мы не можем произвести для вас
                    расчет. Пожалуйста попробуйте еще раз позднее
                  </div>
                )} */}
            </>
          }
        </div>

        {step === 2 &&
          <>
            <div className="steps_form__wrap_all_big_num">
              <div className="steps_form__wrap_cell_double_num">
                <div className="steps_form__cell_double_num">
                  <div className="steps_form__title_num">
                    Условия договора
                  </div>
                  <div className="steps_form__wrap_big_num">
                  <div className="steps_form__big_num">
                    {formatNumber(responseCalculate?.payments?.[0]?.paymentAmount)} ₽
                  </div>
                    <div className="steps_form__wrap_mini_text_big_num">
                      <div className="steps_form__mini_text_big_num">Ежемесячный платёж с учетом НДС</div>
                    </div>
                  </div>
                </div>
                <div className="steps_form__cell_double_num">
                  <div className="steps_form__wrap_big_num">
                    <div className="steps_form__big_num gray-color">
                      {formatNumber(+summAllPayments + +context?.state?.stateStepsForm?.rangePrepayment)} ₽
                    </div>
                    <div className="steps_form__wrap_mini_text_big_num">
                      <div className="steps_form__mini_text_big_num">Сумма договора лизинга</div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="steps_form__wrap_cell_double_num">
                <div className="steps_form__cell_double_num">
                  <div className="steps_form__title_num">
                    Выгода
                  </div>
                  <div className="steps_form__wrap_big_num">
                    <div className="steps_form__big_num gray-color">
                      {formatNumber(responseCalculate?.reverseChargeVat)} ₽
                    </div>
                    <div className="steps_form__wrap_mini_text_big_num">
                      <div className="steps_form__mini_text_big_num">Возврат 20% НДС</div>
                    </div>
                  </div>
                </div>

                <div className="steps_form__cell_double_num">
                  <div className="steps_form__wrap_big_num">
                    <div className="steps_form__big_num gray-color">
                      {formatNumber(responseCalculate?.incomeTaxSavings)} ₽
                    </div>
                    <div className="steps_form__wrap_mini_text_big_num">
                      <div className="steps_form__mini_text_big_num">Снижение налога на прибыль</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {responseCalculate?.payments?.length > 0
              && (
              <div className="steps_form__wrap_section_payment_charts">
                <div
                  className="steps_form__wrap_btn_section_payment_charts"
                  onClick={() => setShowCharts((showCharts) => !showCharts)}
                >
                  <div className="steps_form__btn_text_section_payment_charts">
                    График платежей
                  </div>

                  <div className={`steps_form__btn_icon_section_payment_charts ${showCharts ? 'rotate' : ''}`}>
                    <img src={require('../../assets/blue-arr-down.svg').default} alt="Раскрыть" />
                  </div>
                </div>

                <div className={`steps_form__wrap_payment_charts ${showCharts ? 'active' : ''}`}>
                  <h5 className="steps_form__title_payment_charts">
                    График платежей по лизингу
                  </h5>
                  <div className="steps_form__description_payment_charts">
                    Рублей в месяц, в т.ч. НДС 20%
                  </div>
                  <a href={`data:application/pdf;base64,${responseCalculate?.report}`} download="file.pdf" className="steps_form__link_load">
                    Скачать
                  </a>

                  <div className="steps_form__wrap_all_cell_payment_charts">
                    {responseCalculate?.payments?.slice(0, element).map((item, index) => 
                      <div key={index} className="steps_form__cell_payment_charts">
                        <div className="steps_form__cell_payment_charts_num">{item.paymentNumber}</div>
                        <div className="steps_form__cell_payment_charts_price">
                          {formatNumber(item.paymentAmount)} ₽
                        </div>
                      </div>
                    )}
                  </div>
                  
                  {element >= letgthElement ? null : (
                    <div
                      className="steps_form__wrap_btn_section_payment_charts"
                      onClick={showMore}
                    >
                      <div className="steps_form__btn_text_section_payment_charts">
                        Показать больше
                      </div>

                      <div className="steps_form__btn_icon_section_payment_charts">
                        <img src={require('../../assets/krest.svg').default} alt="Раскрыть" />
                      </div>
                    </div>
                  )}
                </div>

                <div
                  className="steps_form__long_text_payment_charts"
                  dangerouslySetInnerHTML={{ __html: `
                    Настоящий расчет является ориентировочным и&nbsp;не&nbsp;является официальной офертой 
                    для заключения сделки. Для уточнения условий сделки вам нужно  
                    дождаться звонка  нашего специалиста или обратиться  
                    по телефону 8 800 2000 180 (звонок по&nbsp;России бесплатный).
                  ` }}
                />
              </div>
            )}

            <div className="steps_form__wrap_double_wrap_btn">
              <div className={`steps_form__wrap_btn_third_step ${changeBlock ? 'active' : ''}`}>
                <ButtonSf
                  typeBtn='second'
                  disabled={changeBlock || blockFinalStep}
                  onClick={finalStep}
                >
                  Отправить заявку
                </ButtonSf>
              </div>

              {/* <div className="steps_form__wrap_btn long">
                <ButtonSf
                  typeBtn='third'
                  disabled={changeBlock}
                  onClick={() => context.dispatch({ type: 'MODAL', data: { state: true, type: 'special_offer' }, })}
                >
                  Получить персональное предложение
                </ButtonSf>
              </div> */}
            </div>
          </>
        }
      </div>
    </div>
  );
};

export default StepsForm;