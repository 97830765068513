import React from 'react';
import './CardConditions.css';
import ButtonSf from '../UI/ButtonSf/ButtonSf';

const CardConditions = ({
  data = [],
}) => {
  return (
    <div className="card_conditions">
      <div className="card_conditions__wrap_img">
        <img src={data.img} alt="Изображение" />
      </div>

      <div className="card_conditions__wrap_content">
        <h4 className="card_conditions__title">{data.title}</h4>
        <div className="card_conditions__wrap_description">
          {data.typeDescription === 'str' &&
            <div className="card_conditions__description_str">
              Специальное предложение для лизинга спецтехники и транспорта
            </div>
          }
          {data.typeDescription === 'list' &&
            <div className="card_conditions__description_list">
              {data.description.map((item, index) =>
                <div className="card_conditions__line_text" key={index}>
                  <div className="card_conditions__line_text_title">{item.title} -</div>
                  <div className="card_conditions__line_text_description">{item.description}</div>
                </div>
              )}
            </div>
          }
        </div>

        <a href={data.href} target="_blank" rel="noreferrer" className="card_conditions__wrap_btn">
          <ButtonSf typeBtn='fourth'>
            подробнее
          </ButtonSf>
        </a>
      </div>
    </div>
  );
};

export default CardConditions;