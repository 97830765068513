import React, { useContext } from 'react';
import './UniqueSolutionBlock.css';
import ButtonSf from '../UI/ButtonSf/ButtonSf';
import { Context } from '../../globalState/context/context';

const UniqueSolutionBlock = () => {
  const context = useContext(Context);

  return (
    <div className="unique_solution_block">
      <div className="unique_solution_block__wrap_title">
        <h2 className="unique_solution_block__title">Закроем любую потребность</h2>
      </div>

      <div className="unique_solution_block__wrap_double_elements">
        <h5 className="unique_solution_block__title_small">
          Персональный менеджер в вашем регионе поможет оформить лизинг быстрее
        </h5>
        <div className="unique_solution_block__wrap_btn">
          <ButtonSf
            typeBtn='first'
            onClick={() => context.dispatch({ type: 'MODAL', data: { state: true, type: 'online_application' }, })}
          >
            отправить заявку
          </ButtonSf>
        </div>
      </div>

    </div>
  );
};

export default UniqueSolutionBlock;