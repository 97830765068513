/* eslint-disable no-useless-escape */
import React, { useState, useContext, useEffect } from 'react';
import './ContentModalsSpecialOffer.css';
import { fetchHelper } from '../../../../helpers/api-helper';
import ContainerFormElement from '../../../UI/ContainerFormElement/ContainerFormElement';
import ButtonSf from '../../../UI/ButtonSf/ButtonSf';
import TextAreaSf from '../../../UI/TextAreaSf/TextAreaSf';
import InputSf from '../../../UI/InputSf/InputSf';
import CustomInputMask from '../../../UI/CustomInputMask/CustomInputMask';
import GroupRadioButton from '../../../UI/GroupRadioButton/GroupRadioButton';
import Tooltip from '../../../UI/Tooltip/Tooltip';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { Context } from '../../../../globalState/context/context';

const ContentModalsSpecialOffer = ({
  onClose = () => {},
}) => {
  const context = useContext(Context);
  const [showForm, setShowForm] = useState(false); // показывает/скрывает форму
  const [blockButton, setBlockButton] = useState(false); // блокирует кнопку Запросить предложение

  const buttonsData = [
    { name: 'Название организации', id: 'a' },
    { name: 'ИНН', id: 'b' },
  ];

  // для формы ТЕКСТОВОЕ ПОЛЕ
  const schema = yup.object({
    textareaYourQuestion: yup.string()
      .required('Это поле необходимо заполнить')
      .trim()
      .max(255, ' '),
  }).required();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: schema.cast(),
    resolver: yupResolver(schema),
    // mode: "onBlur"
    mode: "onChange"
  });


  



  // для формы ИЗМЕНИТЬ
  const schema2 = yup.object().shape({
    radioFirst: yup.string()
      .typeError('Выберите один из вариантов')
      .required(),
    organizationOrINN: yup.string()
      .required('Это поле необходимо заполнить')
      .trim()
      .when('radioFirst', {
        is: 'b', // если выбрано радиокнопка ИНН, то
        then: (rule) => {
          clearErrors2('organizationOrINN');
          setValue2('organizationOrINN', '');
          return rule.nullable().notRequired();
        }, // поле organizationOrINN становится не обязательным
      }),
    inputINN: yup.string()
      .required('Это поле необходимо заполнить')
      .max(12, 'Максимум 12 символов')
      .matches(/^[0-9]*$/gmi, "Должно быть числом") // разрешены пустая строка и только цифры
      .test("0 or min 10", "Минимум 10 символов", (value) => {
        if (value?.length > 0 && value?.length < 10) return false // разрешена пустая строка и минимум 10 символов
        else return true
      })
      .when('radioFirst', {
        is: 'a', // если выбрано радиокнопка (Название организации), то
        then: (rule) => {
          setValue2('inputINN', '');
          return rule.nullable().notRequired();
        }, // поле inputINN становится не обязательным
      }),
    inputUser: yup.string()
      .required('Это поле необходимо заполнить')
      .trim()
      .matches(/^[-а-яА-ЯёЁ\s]+$/, "Разрешены только кириллица и дефис"),
    inputPhone: yup.string()
      .required('Это поле необходимо заполнить')
      .matches(/^((8|\+7)[\- ]?)?(\(?\d{3}\)?[\- ]?)?[\d\- ]{7,10}$/gmi, "Введите корректный номер телефона"),
    inputEmail: yup.string()
      .required('Это поле необходимо заполнить')
      .email('Пожалуйста, введите корректный адрес электронной почты'),
  }, [['radioFirst', 'organizationOrINN']]); // указываются имена полей для возможности циклической обработки (типо зависимостей)

  const {
    register: register2,
    control: control2,
    watch: watch2,
    setValue: setValue2, // аналог setState как в useState()
    handleSubmit: handleSubmit2,
    unregister: unregister2,
    clearErrors: clearErrors2,
    formState: { errors: errors2, isValid: isValid2 },
  } = useForm({
    defaultValues: schema2.cast(),
    resolver: yupResolver(schema2),
    // mode: "onBlur"
    mode: "onChange"
  });

  // позволяет отслеживать значение элементов в реальном времени (аналог state в useState())
  const radioFirstWatch = watch2('radioFirst');
  const organizationOrINNWatch = watch2('organizationOrINN');
  const inputINNWatch = watch2('inputINN');
  const inputUserWatch = watch2('inputUser');
  const inputPhoneWatch = watch2('inputPhone');
  const inputEmailWatch = watch2('inputEmail');

  // устанавливаем значения в поля
  useEffect(() => {
    setValue2('radioFirst', context?.state?.stateStepsForm?.radioFirst);
    setValue2('organizationOrINN', context?.state?.stateStepsForm?.organizationOrINN);
    setValue2('inputINN', context?.state?.stateStepsForm?.inputINN);
    setValue2('inputUser', context?.state?.stateStepsForm?.inputUser);
    setValue2('inputPhone', context?.state?.stateStepsForm?.inputPhone);
    setValue2('inputEmail', context?.state?.stateStepsForm?.inputEmail);
  }, [
    setValue2,
    context?.state?.stateStepsForm
  ]);

  // отправка формы email
  const submitForm2 = (data) => {
  };

  // отправка формы
  const submitForm = async (data) => {
    setBlockButton(true); // блокируем кнопку
   
    try {
      context.dispatch({
        type: 'STATE_STEPS_FORM',
        data: {
          ...context?.state?.stateStepsForm,
          textareaYourQuestion: data?.textareaYourQuestion,
        },
      });

      await handleSubmit2((i) => {
        submitForm2(i);
      })();

      await fetchHelper(`/landing/v1/schedule/post`, {
        method: 'POST',
        body: JSON.stringify({
          calculationUID: context?.state?.responsePostCalculate?.uid,
          assetName: context?.state?.stateStepsForm?.selectCategory?.name,
          typeId: 3,
          customer: {
            companyName: context?.state?.stateStepsForm?.organizationOrINN,
            companyTIN: context?.state?.stateStepsForm?.inputINN,
            companyActivityPeriodId: context?.state?.stateStepsForm?.selectTermWork?.id,
            regionId: context?.state?.stateStepsForm?.selectLocation?.id,
            person: context?.state?.stateStepsForm?.inputUser,
            phone: context?.state?.stateStepsForm?.inputPhone,
            email: context?.state?.stateStepsForm?.inputEmail,
          },
          comment: data?.textareaYourQuestion,
        }),
      });

      setBlockButton(true); // разблокируем кнопку
    } catch (err) {
      console.log('ошибка в POST запросе post --- редактирование второго шага');
      context.dispatch({ type: 'RESPONSE_POST_ERROR', data: err });
    }

    // закрываем моладку и открываем преветственную модалку
    context.dispatch({ type: 'MODAL', data: { state: false, type: 'special_offer' }, });
    setTimeout(() => {
      context.dispatch({ type: 'MODAL', data: { state: true, type: 'success' }, });
    }, 1000);
  }

  // если выбрано (Название организации), то ИНН не обязательным становится,
  useEffect(() => {
    if (radioFirstWatch === 'a') unregister2('inputINN');
  }, [radioFirstWatch, unregister2]);
  
  // по нажатию на крестик в верху модального окна
  const handlerClose = () => {
    onClose();
  };

  return (
    <div className="content_modals_special_offer">
      {!showForm
        && (
          <div 
            className="modal_sf_wrap_close"
            onClick={handlerClose}
          > 
            <img
              className="modal_sf_wrap_close_img"
              src={require('../../../../assets/close.svg').default}
              alt="Закрыть" 
            />
          </div>
        )}
      
      <h3 className="content_modals_special_offer__title">
        Получить персональное предложение
      </h3>

      <div className='content_modals_special_offer__wrap_text_and_btn'>
        {!showForm
          && (
            <div className='content_modals_special_offer__wrap_text'>
              <span>{organizationOrINNWatch && organizationOrINNWatch}</span>
              <span>{inputINNWatch && inputINNWatch}</span>
              <span>{inputUserWatch}</span>
              <span>{inputPhoneWatch}</span>
              <span>{inputEmailWatch}</span>
            </div>
          )}

        {showForm
          && (
            <div className="content_modals_special_offer__form">
              <div className="steps_form__wrap_radiobuttons_step_2">
                <GroupRadioButton
                  data={buttonsData}
                  value={radioFirstWatch}
                  validation={{
                    ...register2("radioFirst")
                  }}
                />
                <div className="steps_form__wrap_radiobuttons_icon">
                  <Tooltip 
                    messageTooltip={`
                      данная информация собирается для ускорения рассмотрения заявки 
                      и подготовки индивидуального предложения.
                    `}
                    rightPosotion
                  >
                    <img src={require('../../../../assets/info.svg').default} alt="Информация" />
                  </Tooltip>
                </div>
              </div>

              <div className={`steps_form__wrap_input_org_or_inn ${radioFirstWatch === 'b' ? 'hidden' : ''}`}>
                <ContainerFormElement textLabel={'Название организации'}>
                  <InputSf
                    placeholder={'ООО «Компания»'}
                    error={errors2.organizationOrINN}
                    validation={{
                      ...register2("organizationOrINN")
                    }}
                  />
                {errors2.organizationOrINN && <p className="steps_form__line__text_error">{errors2.organizationOrINN.message}</p>}
                </ContainerFormElement>
              </div>

              <div className={`steps_form__wrap_input_org_or_inn ${radioFirstWatch === 'a' ? 'hidden' : ''}`}>
                <ContainerFormElement textLabel={'ИНН'}>
                  <InputSf
                    placeholder={'Введите ИНН'}
                    error={errors2.inputINN}
                    maxlength={12}
                    onlyNum
                    setValue={setValue2}
                    validation={{
                      ...register2("inputINN")
                    }}
                  />
                {errors2.inputINN && <p className="steps_form__line__text_error">{errors2.inputINN.message}</p>}
                </ContainerFormElement>
              </div>

              <ContainerFormElement textLabel="Телефон">
                <CustomInputMask
                  control={control2}
                  error={errors2.inputPhone}
                  name={"inputPhone"}
                />
                {errors2.inputPhone && <p className="steps_form__line__text_error">{errors2.inputPhone.message}</p>}
              </ContainerFormElement>

              <ContainerFormElement textLabel="Контактное лицо">
                <InputSf
                  placeholder="Иванов Сергей Сергеевич"
                  error={errors2.inputUser}
                  validation={{
                    ...register2("inputUser")
                  }}
                />
                {errors2.inputUser && <p className="steps_form__line__text_error">{errors2.inputUser.message}</p>}
              </ContainerFormElement>

              <ContainerFormElement textLabel="Адрес эл. почты">
                <InputSf
                  placeholder="ivanov@mail.ru"
                  error={errors2.inputEmail}
                  validation={{
                    ...register2("inputEmail")
                  }}
                />
                {errors2.inputEmail && <p className="steps_form__line__text_error">{errors2.inputEmail.message}</p>}
              </ContainerFormElement>

              <div className="content_modals_special_offer__wrap_btn">
                <ButtonSf
                  typeBtn='second'
                  disabled={!isValid2}
                  onClick={() => {
                    if (isValid2) {
                      setTimeout(() => {
                        setShowForm(false);
                      }, 1000);

                      context.dispatch({
                        type: 'STATE_STEPS_FORM',
                        data: {
                          ...context?.state?.stateStepsForm,
                          radioFirst: radioFirstWatch,
                          organizationOrINN: organizationOrINNWatch,
                          inputINN: inputINNWatch,
                          inputUser: inputUserWatch,
                          inputPhone: inputPhoneWatch,
                          inputEmail: inputEmailWatch,
                        },
                      });
                    }
                    return null;
                  }}
                >
                  Подтвердить
                </ButtonSf>
              </div>
            </div>
          )}

        {!showForm 
          && (
            <div className='content_modals_special_offer__wrap_btn_edit'>
              <ButtonSf
                typeBtn='third'
                onClick={() => setShowForm(true)}
              >
                Изменить
              </ButtonSf>
            </div>
          )}
      </div>
      
      {!showForm
        && (
          <form onSubmit={handleSubmit(submitForm)} className="form">
            <ContainerFormElement textLabel="Задайте ваш вопрос, максимум 255 символов">
              <TextAreaSf
                placeholder=""
                error={errors.textareaYourQuestion}
                validation={{
                  ...register("textareaYourQuestion")
                }}
              />

              {errors.textareaYourQuestion && <p className="steps_form__line__text_error">{errors.textareaYourQuestion.message}</p>}
            </ContainerFormElement>

            <div className="content_modals_special_offer__text">
              Мы рассмотрим ваше пожелание и поможем подобрать для вас наилучший вариант
            </div>

            <div className="content_modals_special_offer__wrap_btn">
              <ButtonSf
                typeBtn='second'
                type="submit"
                disabled={blockButton}
              >
                Запросить предложение
              </ButtonSf>
            </div>
          </form>
        )}
    </div>
  );
};

export default ContentModalsSpecialOffer;