import React, { useRef } from 'react';
import Slider from "react-slick";
import '../../assets/slick/slick.css';
import './MainSlider.css';
import ButtonSf from '../UI/ButtonSf/ButtonSf';
import { useWindowSize } from '../../hooks/useWindowSize';

const MainSlider = ({
  photos = [],
}) => {
  const sliderRef = useRef(null);
  const [widthWindow] = useWindowSize();

  const settings = {
    dots: false,
    infinite: true,
    // autoplay: true,
    speed: 1000,
    autoplaySpeed: 5000,
    slidesToShow: 1,
    fade: true,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1366,
        settings: {
          dots: true,
        }
      },
    ]
  };

  // на определенном брейкпоинте отображаем нужну картинку
  const showNumImg = () => {
    let num = 0;

    if (widthWindow <= 768) return num = 0;
    else if (widthWindow > 768 && widthWindow <= 1366) return num = 1;
    else if (widthWindow > 1366 && widthWindow <= 1920) return num = 2;
    else if (widthWindow > 1920) return num = 3;

    return num;
  };

  return (
    <div className="main_slider">
      <Slider
        {...settings}
        ref={sliderRef}
      >
        {photos?.map((photo, index) => (
          <div className="main_slider__slide" key={index}>
            <div className="main_slider__content">
              <div className="main_slider__content_wrap_img">
                <img src={photo.arrImg[showNumImg()].img} alt="Изображение" />
              </div>

              <div
                className="main_slider__wrap_central_content"
                style={{zIndex: index + 1}}
              >
                <div className="main_slider__central_content">
                  <div className="main_slider__top_part_first_block">
                    <h2
                      className="main_slider__title"
                      dangerouslySetInnerHTML={{ __html: photo?.title }}
                    />
                    <div className="main_slider__top_part_all_items">
                      {photo?.description?.map((i, index2) =>
                        <div className="main_slider__top_part_items" key={index2}>
                          <div
                            className="main_slider__top_part_item"
                            dangerouslySetInnerHTML={{ __html: i?.text }}
                          />
                        </div>
                      )}
                    </div>

                    {photo?.descriptionBtn
                      && (
                        <div
                          className="main_slider__top_part_description_btn"
                          dangerouslySetInnerHTML={{ __html: photo?.descriptionBtn }}
                        />
                      )}

                    {photo?.href
                      && (
                        <a
                          className="main_slider__wrap_btn"
                          href={photo?.href}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <ButtonSf typeBtn={photo?.typeBtn}>
                            {photo?.titleBtn}
                          </ButtonSf>
                        </a>
                      )}

                    {photo?.onClick
                      && (
                        <span
                          className="main_slider__wrap_btn"
                          onClick={photo?.onClick}
                        >
                          <ButtonSf typeBtn={photo?.typeBtn}>
                            {photo?.titleBtn}
                          </ButtonSf>
                        </span>
                      )}

                  </div>
                </div>
              </div>

              <button
                className="main_slider__button_prev"
                onClick={() => { sliderRef.current.slickPrev() }}
              >
                <img src={require('../../assets/arr_left.svg').default} alt="Влево" />
              </button>

              <button
                className="main_slider__button_next"
                onClick={() => { sliderRef.current.slickNext() }}
              >
                <img src={require('../../assets/arr_right.svg').default} alt="Вправо" />
              </button>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  )
};

export default MainSlider;