/* eslint-disable react/jsx-props-no-multi-spaces */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useRef, useEffect } from 'react';
import './YandexMapStyles.css';
import { react_app_public_yandex_api_key } from '../../helpers/config-helper';
import {
  YMaps, Map, Placemark, GeolocationControl, ZoomControl,
} from 'react-yandex-maps';

const YandexMap = function ({
  isCoordinates = [],
  offices = [],
  setNearestCityFinding = () => { },
}) {
  const map = useRef();
  const [ymaps, setYmaps] = useState(null);
  const [locationUser, setLocationUser] = useState('');

  // собираем массив с коорджинатами городов для geoQuery
  const arrCityCoordinates = offices.map((i) => ({
    type: "Point",
    coordinates: [i?.coordinates?.latitude, i?.coordinates?.longitude],
    name: i.name
  }))

  // определяем местоположение
  useEffect(() => {
    if (ymaps) {
      ymaps.geolocation.get({
        // Зададим способ определения геолокации на основе ip пользователя.
        provider: 'yandex',
        // Включим автоматическое геокодирование результата.
        autoReverseGeocode: true
      }).then(function (result) {
        // полное описание местонахождения пользователя
        const findingLocationUser = result.geoObjects.get(0).properties.get('text');
        setLocationUser(findingLocationUser);
      });

      // все города с координатами
      let allCityCoordinstes = ymaps.geoQuery(arrCityCoordinates);;
      // Координаты пользователя
      let coordinatesUser = ymaps.geoQuery(ymaps.geocode(locationUser)).then(findClosestObjects);

      function findClosestObjects() {
        // Найдем в выборке городов, ближайший город
        const nearestСity = allCityCoordinstes.getClosestTo(coordinatesUser.get(0));
        // получим координаты ближайшего найденного города
        const latitude = nearestСity.geometry.getCoordinates()[0];
        const longitude = nearestСity.geometry.getCoordinates()[1];
        // найдем название города путем сравнения координат
        const comparisonOfCoordinates = arrCityCoordinates.find((i) => i.coordinates.toString() === [latitude, longitude].toString());
        setNearestCityFinding(comparisonOfCoordinates?.name);

      }
    }
  }, [ymaps, locationUser, arrCityCoordinates, setNearestCityFinding]);

  // устанавливает центр карты после смены координат
  useEffect(() => {
    map.current?.panTo(isCoordinates, { // плавное перемещение от точки к точке
      flying: true,
      duration: 1000,
    });
  }, [isCoordinates]);

  return (
    <div className="yandex_map">
      {/* ключ необходим для отображения маршрутов */}
      <YMaps
        query={{ lang: 'ru_RU', apikey: process.env.REACT_APP_PUBLIC_YANDEX_API_KEY || react_app_public_yandex_api_key }}
      >
        <Map
          modules={['geolocation', 'geocode', 'geoQuery']}
          defaultState={{ center: isCoordinates, zoom: 15 }}
          height="100%"
          width="100%"
          options={{
            suppressMapOpenBlock: true, // убирает с карты кнопку КАК ДОБРАТЬСЯ
          }}
          instanceRef={map}
          onLoad={(e) => {
            // после того как карта прогрузилась отключаем масштабирование колесиком мышки
            map.current?.behaviors?.disable('scrollZoom');
            // записываем в состояние event карты
            setYmaps(e);
          }}
        >
          <Placemark
            geometry={isCoordinates}
            options={{
              iconLayout: 'default#image',
              // своя метка
              // iconImageHref: 'images/mark.svg',
              // iconImageSize: [48, 60],
              // iconImageOffset: [-24, -60],
            }}
          />
          <GeolocationControl options={{ float: 'left' }} />
          <ZoomControl options={{ float: 'right' }} />
        </Map>
      </YMaps>
    </div>
  );
};

export default YandexMap;
