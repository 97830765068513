import { useState, useEffect } from 'react';

// кастомный хук ширины и высоты экрана
export const useWindowSize = () => {
  const [sizeWindow, setWindowSize] = useState([window.innerWidth, window.innerHeight]);

  useEffect(() => {
    const handlWidth = () => setWindowSize([window.innerWidth, window.innerHeight]);
    window.addEventListener('resize', handlWidth);

    return () => {
      window.removeEventListener('resize', handlWidth);
    }
  });
  return sizeWindow;
}