import React from 'react';
import Cookies from 'js-cookie';


function CookieModal() {
    const setCookies = () => {
        Cookies.set('agree_cookies_promo', true)
        document.querySelector(".modal__cookies").style.display = "none"
    }

    return (
        <div className="modal__cookies">
            <p>
                Мы обрабатываем файлы Cookies согласно условиям в <a
                href="https://site.deltaleasing.ru/confidentiality" target="_blank" rel="noreferrer">Политике
                конфиденциальности</a>.
                Нажимая «ОК», вы даете свое согласие на обработку ваших Cookies. Отказаться от обработки вы
                всегда можете в настройках своего браузера.
            </p>
            <button className="button second modal__cookies_btn" type="button" onClick={setCookies}>
                <span className="button__text">ОК</span>
            </button>
        </div>
    );
}

export default CookieModal;