import React from 'react';
import Slider from 'rc-slider';
import { Controller } from "react-hook-form";
import './InputRange.css';
import { formatNumber } from '../../../helpers/string-utils';

const InputRange = ({
  min = 0,
  max = 10,
  step = 1,
  maxlength,
  inputValidation = {},
  rangeValidation = {},
  control,
  setValue,
  valueInput,
  valueRange,
  blockInput = false,
  error = false,
  disabled = false,
  placeholder = '',
}) => {

  return(
    <div className="input_range__wrap">
      <div className="input_range__wrap_input">
        
        <input
          className={`input_sf ${error ? 'error' : ''}`}
          type="text"
          disabled={disabled}
          placeholder={placeholder}
          readOnly={blockInput}
          {...inputValidation}
          value={formatNumber(parseInt(valueInput))}
          onBlur={(e) => {
            // создает возможность использовать только цифры и создавать диапазон при этом можно 
            // редактировать минимальное число и если оно меньше чем дозволено, то при потери фокуса
            // число становится минимальным
            let value = +e.target.value.replace(/\D/g, '') || 0;
            e.target.value = Math.min(max, Math.max(min, value));
            inputValidation.onChange(e)
            setValue(rangeValidation.name, +e.target.value);
          }}
          onChange={(e) => { // используется вместе с onBlur, что бы при редактировании минималдьного числа не появлялся NaN
            let value = +e.target.value.replace(/\D/g, '') || 0;
            e.target.value = Math.min(max, value);
            inputValidation.onChange(e)
            setValue(rangeValidation.name, +e.target.value);
          }}
          maxLength={maxlength}
        />
      </div>

      <div className="input_range">
        <Controller
          name={rangeValidation.name}
          control={control}
          render={({ field }) => (
            <Slider
              {...field}
              value={valueRange}
              disabled={disabled}
              onChange={(e) => {
                field.onChange(e)
                setValue(inputValidation.name, +e);
              }}
              min={min}
              max={max}
              step={step}
            />
          )}
        />
      </div>
    </div>
  )
};

export default InputRange;
