import { react_app_api_url } from './config-helper';

// вспомогательная обертка для запросов
export const fetchHelper = async (url, data = {}) => {
  const api = await process.env.REACT_APP_API_URL || react_app_api_url;

  const fetchData = data;
  if (!fetchData.headers) fetchData.headers = {};
  const additionalHeaders = {};

  // additionalHeaders.Authorization = `Bearer ${token}`;
  if (fetchData.body) additionalHeaders['Content-Type'] = 'application/json';
  fetchData.headers = { ...fetchData.headers, ...additionalHeaders };

  const response = await fetch(`${api}${url}`, fetchData);
  return response;
};

//вспомогательная обертка для запросов авторизации
export const fetchHelperAuth = async (url, data = {}) => {
  const api = await process.env.REACT_APP_API_URL || react_app_api_url;

  const fetchData = data;
  if (!fetchData.headers) fetchData.headers = {};
  const additionalHeaders = {};

  // additionalHeaders.Authorization = `Bearer ${token}`;
  // if (fetchData.body) additionalHeaders['Content-Type'] = 'application/json';
  fetchData.headers = { ...fetchData.headers, ...additionalHeaders };

  const response = await fetch(`${api}${url}`, fetchData);
  return response;
};
