import React from 'react';
import './ContentModalsSuccess.css';

const ContentModalsSuccess = ({
  onClose = () => {},
}) => {
  return (
    <div className="content_modals_success">
      <div 
        className="modal_sf_wrap_close"
        onClick={onClose}
      >
        <img src={require('../../../../assets/close.svg').default} alt="Закрыть" />
      </div>
      <h3 className="content_modals_success__title">
        Спасибо, мы Вам перезвоним
      </h3>
    </div>
  );
};

export default ContentModalsSuccess;