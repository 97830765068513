import React from 'react';
import './CheckboxSf.css';

const CheckboxSf = ({
  label = 'Текст',
  validation = {},
  check = true,
  disabled = false,
}) => {

  return (
    <label className="checkbox_sf">
      <input
        {...validation}
        type="checkbox"
        className="checkbox_sf__input"
        checked={check}
        disabled={disabled}
      />
      <div className={`checkbox_sf__pseudo_input ${check ? 'active' : ''}`}>
        <img src={require('../../../assets/check.svg').default} alt="Чекбокс" />
      </div>

      <div
        className="checkbox_sf__label" 
        dangerouslySetInnerHTML={{ __html: label }}
      />
    </label>
  );
};

export default CheckboxSf;